import { NgModule, APP_INITIALIZER } from '@angular/core';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { environment } from '../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> =>
        keycloak.init({
            config: environment.settings.auth.keycloak,
            initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false
            },
            enableBearerInterceptor: false // bearer is added in api service
        });
}

@NgModule({
    imports: [KeycloakAngularModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService]
        }
    ]
})
export class AppAuthModule {}
