import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { EntityTypes } from '../../models/entity';

@Injectable()
export class AccessService extends AbstractService {
    constructor(injector: Injector, private translate: TranslateService) {
        super(injector);
        this.endpoint = 'gdi-entreprise/keycloak';
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    /**
     * Get redirect URL of requested service Region if current user meets all required criteria
     *
     * @param entityType "company" or "project" ("transformed_project" considerd as "project")
     * @param service Region's service name on which current user wants to access
     * @param entityName which current user want to access with
     * @return requested service URL if user granted, 404 if requested service not found, 403 with error message explaining why user is forbidden
     */
    getRedirectURL(service: string, entityType?: string, entityName?: string): Promise<HttpResponse<any>> {
        let pHistory = false;
        if (entityType && entityName) {
            if (entityType === EntityTypes.TransformedProject) {
                entityType = EntityTypes.Company;
                pHistory = true;
            }
            return this.api.get(`${this.endpoint}/access/${entityType}/${service}/${pHistory}/${entityName}`);
        } else {
            return this.api.get(`${this.endpoint}/access/${service}`);
        }
    }

    /**
     * Get redirect URL of requested service Region without specific criteria
     * @param service Region's service name on which current user wants to access
     * @return requested service URL if user granted, 404 if requested service not found
     */
    getRedirectURLWithoutRestriction(service: string): Promise<HttpResponse<any>> {
        return this.api.get(`${this.endpoint}/access/${service}`);
    }

    checkAccessForSelectedEntreprise(entityType: string, entityName: string): Promise<any> {
        let pHistory = false;
        if (entityType === EntityTypes.TransformedProject) {
            entityType = EntityTypes.Company;
            pHistory = true;
        }
        return this.api.get(`${this.endpoint}/selection/${entityType}/${pHistory}/${entityName}`);
    }
}
