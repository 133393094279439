import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { GroupService } from '../services/gdi-api/group.service';
import { ModalService } from '../services/modal/modal.service';
import { EntityRoles } from '../models/entity';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class GestionGuard implements CanActivate {
    constructor(
        protected auth: AuthService,
        protected api: GroupService,
        protected modal: ModalService,
        protected translate: TranslateService
    ) {}

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const redirectUri = next.queryParams['redirect_uri'];
        const siret = next.params['id'];

        return this.auth.getUser().then(user => {
            if (!this.auth.getUserRoles().includes('VERIFIED')) {
                return false;
            }
            // this.modal.loader(true);
            if (user && redirectUri) {
                const isBoundToSiretNumber = this.auth.getEntities().find(entity => {
                    return entity.name.replace(/\s/g, '') === siret.replace(/\s/g, '');
                });
                if (isBoundToSiretNumber) {
                    if (isBoundToSiretNumber.role === EntityRoles.Mandataire) {
                        this.modal.loader(false);
                        return true;
                    } else {
                        this.modal.alert(this.t("Vous n'avez pas les droits nécessaire pour accéder à cette page."));
                        return this.auth.refreshUser().then(result => {
                            this.modal.loader(false);
                            window.location.href = redirectUri;
                            return false;
                        });
                    }
                } else {
                    this.modal.alert(this.t("Vous n'avez pas les droits nécessaire pour accéder à cette page."));
                    return this.auth.refreshUser().then(result => {
                        this.modal.loader(false);
                        window.location.href = redirectUri;
                        return false;
                    });
                }
            } else if (redirectUri && !user) {
                // The user is not connected or no redirect_uri in query params
                return this.auth.refreshUser().then(result => {
                    this.modal.loader(false);
                    window.location.href = redirectUri;
                    return false;
                });
            } else {
                this.modal.alert(this.t("Vous n'avez pas les droits nécessaire pour accéder à cette page."));
                return this.auth.refreshUser().then(result => {
                    this.modal.loader(false);
                    window.location.href = redirectUri;
                    return false;
                });
            }
        });
    }
}
