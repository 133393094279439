<form (ngSubmit)="onSubmit()" #siretForm="ngForm" [class.onerror]="errorState">
    <div class="form-group">
        <label for="siret" class="label"><span translate>EnterSIRETNumber</span> *</label>
        <br/>
        <input
            type="text"
            class="form-control"
            id="siret"
            [(ngModel)]="siret"
            name="siret"
            required
            [autofocus]="autofocus"
            #siretInput
            #siretNgModel="ngModel"
            pattern="[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{5}"
            placeholder="XXX XXX XXX XXXXX"
            maxlength="17"
            (keyup.enter)="onSubmit()"
            (ngModelChange)="formatSiret($event)"
        />
        <div *ngIf="errorState">
            <small translate>{{ errorMessage }}</small>
        </div>

        <div *ngIf="!this.transformProject || this.transformProject === ''" class="list-project">
            <group class="form-check form-control-civility">
                <legend class="title" translate>linkWithProject</legend>
                <div *ngFor="let project of projectEntities" class="radio row align-items-center ">

                    <input
                        type="radio"
                        name="projectChoice"
                        value="{{ project.name }}"
                        id="{{ project.name }}"
                        (change)="onItemChange($event.target.value)"
                        [checked]="projectID === project.name"

                    />
                    <label for="{{ project.name }}" class="project col-9">{{ project.displayName }}</label>
                </div>

                <div class="radio row align-items-center">
                    <input type="radio" id="noProject" name="projectChoice" (change)="onItemChange(null)"
                           [checked]="projectID === null"
                           />
                    <label for="noProject" class="project col-9" translate>noProject</label>
                </div>

            </group>


        </div>
    </div>

    <div *ngIf="connectProject !== null" class="spacing"></div>

    <button type="submit"  class="btn text-align-center" [disabled]="!siretForm.form.valid && projectID !== undefined"
            translate >
        {{ submitAction }}
    </button>
</form>
