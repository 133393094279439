import { Injectable } from '@angular/core';

import { EncryptorService } from './encryptor.service';
import { environmentLoader } from '../../environments/environmentLoader';

@Injectable()
export class LocalStorageService {
    private redirectUri = 'REDIRECT_URI';
    private project = 'PROJECT';
    private projectId = 'PROJECT_ID';
    private siret = 'SIRET';
    private company = 'COMPANY';
    private accessProject = 'ACCESS_PROJECT';
    private transform = 'TRANSFORM';
    private isAssociation = 'ASSOCIATION';
    private service = 'SERVICE';

    private defaultService = '';

    constructor(private encryptorService: EncryptorService) {
        environmentLoader.then(env => {
            this.defaultService = `${env.settings.service.default}`;
        });
    }

    /**
     * Stock the value of the 'redirect_uri'
     *
     * @param redirectUri The redirect_uri value
     */
    setRedirectUri(redirectUri?: string) {
        window.localStorage.setItem(this.redirectUri, this.encryptorService.encrypt(redirectUri));
    }

    /**
     * Get the 'redirectUri' value
     */
    getRedirectUri() {
        const redirectUri = window.localStorage.getItem(this.redirectUri);
        return !redirectUri ? window.location.href : this.encryptorService.decrypt(redirectUri);
    }

    /**
     * Delete the value in the local storage for 'redirect_uri'
     */
    deleteRedirectUri() {
        window.localStorage.removeItem(this.redirectUri);
    }

    /**
     * Stock the value of the 'company'
     *
     * @param company The company value
     */
    setCompany(company: any) {
        window.localStorage.setItem(this.company, this.encryptorService.encrypt(company));
    }

    /**
     * Get the 'company' value
     */
    getCompany() {
        const company = window.localStorage.getItem(this.company);
        return !company ? {} : this.encryptorService.decrypt(company);
    }

    /**
     * Delete the value in the local storage for 'company'
     */
    deleteCompany() {
        window.localStorage.removeItem(this.company);
    }

    /**
     * Stock the value of the 'siret'
     *
     * @param siret The siret value
     */
    setSiret(siret?: string) {
        window.localStorage.setItem(this.siret, this.encryptorService.encrypt(siret));
    }

    /**
     * Get the 'siret' value
     */
    getSiret() {
        const siret = window.localStorage.getItem(this.siret);
        return !siret ? '' : this.encryptorService.decrypt(siret);
    }

    /**
     * Delete the value in the local storage for 'siret'
     */
    deleteSiret() {
        window.localStorage.removeItem(this.siret);
    }

    /**
     * Stock the value of the 'project'
     *
     * @param project The siret value
     */
    setProjectName(project?: string) {
        window.localStorage.setItem(this.project, this.encryptorService.encrypt(project));
    }

    /**
     * Get the 'project' value
     */
    getProjectName() {
        const project = window.localStorage.getItem(this.project);
        return !project ? '' : this.encryptorService.decrypt(project);
    }

    /**
     * Delete the value in the local storage for 'project'
     */
    deleteProject() {
        window.localStorage.removeItem(this.project);
    }

    /**
     * Stock the value of the 'projectId'
     *
     * @param projectId The siret value
     */
    setProjectId(projectId?: string) {
        window.localStorage.setItem(this.projectId, this.encryptorService.encrypt(projectId));
    }

    /**
     * Get the 'projectId' value
     */
    getProjectId() {
        const projectId = window.localStorage.getItem(this.projectId);
        return !projectId ? '' : this.encryptorService.decrypt(projectId);
    }

    /**
     * Delete the value in the local storage for 'projectId'
     */
    deleteProjectId() {
        window.localStorage.removeItem(this.projectId);
    }

    /**
     * Stock the value of the 'accessProject'
     *
     * @param accessProject The siret value
     */
    setAccessProject(accessProject?: string) {
        window.localStorage.setItem(this.accessProject, this.encryptorService.encrypt(accessProject));
    }

    /**
     * Get the 'accessProject' value
     */
    getAccessProject() {
        const accessProject = window.localStorage.getItem(this.accessProject);
        return !accessProject ? '' : this.encryptorService.decrypt(accessProject);
    }
    /**
     * Delete the value in the local storage for 'accessProject'
     */
    deleteAccessProject() {
        window.localStorage.removeItem(this.accessProject);
    }

    /**
     * Stock the value of 'transform'
     *
     * @param transform if a project is to be transformed into a company
     */
    setTransformProject(transform?: string) {
        window.localStorage.setItem(this.transform, this.encryptorService.encrypt(transform));
    }

    /**
     * Get the 'transform' value
     */
    getTransformProject() {
        const transform = window.localStorage.getItem(this.transform);
        return !transform ? '' : this.encryptorService.decrypt(transform);
    }

    /**
     * Delete the value in the local storage for 'transform'
     */
    deleteTransformProject() {
        window.localStorage.removeItem(this.transform);
    }

    /**
     * Stock the value of the 'isAssociation'
     *
     * @param isAssociation
     */
    setIsAssociation(isAssociation?: string) {
        window.localStorage.setItem(this.isAssociation, this.encryptorService.encrypt(isAssociation));
    }

    /**
     * Get the 'isAssociation' value
     */
    getIsAssociation() {
        const isAssociation = window.localStorage.getItem(this.isAssociation);
        return !isAssociation ? '' : this.encryptorService.decrypt(isAssociation);
    }

    /**
     * Delete the value in the local storage for 'isAssociation'
     */
    deleteIsAssociation() {
        window.localStorage.removeItem(this.isAssociation);
    }

    setService(service: string) {
        if (service && typeof service == 'string') {
            window.localStorage.setItem(this.service, this.encryptorService.encrypt(service));
        } else {
            window.localStorage.setItem(this.service, this.encryptorService.encrypt(this.defaultService));
        }
    }

    getService() {
        const service = window.localStorage.getItem(this.service);
        return !service ? this.defaultService : this.encryptorService.decrypt(service);
    }
}
