import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';

@Injectable()
export class ProjectService extends AbstractService {
    constructor(injector: Injector) {
        super(injector);
        this.endpoint = 'gdi-entreprise/keycloak';
    }

    /**
     * This method call API endpoint to create current user project if name is available
     *
     * @param {string} projectName
     * @memberof CompanyService
     */
    joinProject(projectName: string): Promise<any> {
        return this.api.post(`${this.endpoint}/joinGroup/${projectName}`);
    }

    /**
     * Create project's Keycloak group representation
     * @param projectName to create
     */
    createProject(projectName: string): Promise<any> {
        return this.api.post(`${this.endpoint}/createProject`, { projectName });
    }

    /**
     * Call API endpoint to get current user project named by passed projectName
     *
     * @param projectName of the searched user project
     */
    getUserProjectByName(projectName: string): Promise<any> {
        return this.api.get(`${this.endpoint}/projets/${projectName}`);
    }
}
