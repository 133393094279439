<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="col-11 occ-modal-title" translate></h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="occ-modal-body">
            <div class="row" *ngIf="showIframe">
                <div class="col">
                    <app-netheos-iframe [accessToken]="accessToken" (documentAccepted)="checkedIdentity()"></app-netheos-iframe>
                </div>
            </div>
            <div class="row" *ngIf="!showIframe">
                <div class="col">
                    <netheos-form
                        [identityOK]="identityOK"
                        [showReCaptcha]="showReCaptcha"
                        [siteKey]="siteKey"
                        (nextStep)="nextStep()"
                    ></netheos-form>
                    <button *ngIf="identityOK" class="btn" (click)="hide()" [attr.aria-label]="'Close' | translate"
                            translate>Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-modal>
