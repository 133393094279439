import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/gdi-api/api.service';
import { NetheosService } from 'src/app/services/gdi-api/netheos.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { netheos, netheosEventAction, netheosEventSignbook, netheosStatusOfFiles } from '../../constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnDestroy {
    accessToken: string;
    netheosUrl = '';
    showIframe = false;
    showWaitingMessage = false;
    isLoading = true;
    redirectUri: string;
    user: User;
    isAssociation = 'false';

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private authService: AuthService,
        private router: Router,
        private netheosService: NetheosService,
        public localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        this.isAssociation = this.localStorageService.getIsAssociation();
        // Get the redirect uri for the logout
        this.subscriptions.push(
            this.route.queryParams.subscribe((params: Params) => {
                this.redirectUri = params['redirect_uri'] ? params['redirect_uri'] : this.localStorageService.getRedirectUri();
            })
        );
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                // Si on a un id dans l'url c'est que le dossier chez Netheos existe déjà, on va juste l'uploadé
                if (params['id']) {
                    this.accessToken = params['id'];
                    this.netheosUrl = this.apiService.netheosUrl.concat(netheos.signbook).concat(this.accessToken);
                    this.showIframe = true;
                } else if (params['workflowLabel']) {
                    if (params['workflowLabel'] === netheos.authentificationAvecCerfa) {
                        this.localStorageService.setIsAssociation('true');
                    }
                    if (
                        params['workflowLabel'] === netheos.authentificationSeule ||
                        params['workflowLabel'] === netheos.authentificationAvecCerfa
                    ) {
                        this.netheosService
                            .createDossier(
                                netheos.authentificationAvecCerfa,
                                this.localStorageService.getProjectId()
                                    ? this.localStorageService.getProjectId()
                                    : this.localStorageService.getSiret()
                            )
                            .then(data => {
                                this.router.navigateByUrl(`/uploadFile/${data.messages.accesstoken}?redirect_uri=${this.redirectUri}`);
                            });
                    } else {
                        // Pas le bon workflowlabel donc sert a rien d'aller plus loin... On fait quoi ?
                    }
                }
            })
        );

        if (this.netheosUrl !== '') {
            this.subscriptions.push(
                this.netheosService.messageEvent$.subscribe(event => {
                    // To avoid error in the browser console, we skip the 'unchanged' data
                    if (event !== netheosEventSignbook.unchanged) {
                        event = JSON.parse(event);

                        switch (event.type) {
                            case netheosEventSignbook.userEvent:
                                if (event.action === netheosEventAction.exit) {
                                    this.showIframe = false;
                                    this.authService.logout(this.redirectUri);
                                }
                                break;

                            case netheosEventSignbook.displayEvent:
                                break;

                            case netheosEventSignbook.clientFileEvent:
                                // If the documents are ok, go to the validation page
                                if (event.state === netheosStatusOfFiles.accepted) {
                                    this.router.navigateByUrl(`/validation?redirect_uri=${this.redirectUri}`);
                                }
                                // If we need a manual validation, hide the iframe and show a message
                                if (event.state === netheosStatusOfFiles.waiting) {
                                    this.showIframe = false;
                                    this.showWaitingMessage = true;
                                    this.isLoading = false;
                                }
                                break;

                            default:
                                break;
                        }

                        this.isLoading = false;
                    }
                })
            );
        } else {
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
