<div id="app-content">
    <div id="container">
        <app-header></app-header>

        <main id="content">
            <app-main-menu></app-main-menu>
            <section id="mainSection">
                <div class="intro mobile-only" translate>
                    siteIntro
                </div>
                <router-outlet></router-outlet>
            </section>

            <app-loader></app-loader>
            <app-alert></app-alert>
            <app-alert-action></app-alert-action>
            <app-confirm></app-confirm>
            <netheos-form-modal [siteKey]="siteKey" class="identity-modal"></netheos-form-modal>
            <netheos-associations-modal [siteKey]="siteKey" class="identity-modal"></netheos-associations-modal>
            <app-accessibilite-modal></app-accessibilite-modal>
            <app-cgu-modal></app-cgu-modal>
            <app-cookies-modal></app-cookies-modal>
            <app-rgpd-modal></app-rgpd-modal>
            <app-plandusite-modal></app-plandusite-modal>
        </main>
        <app-footer></app-footer>
    </div>
</div>
