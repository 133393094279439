<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>cookies-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <h3 id="section5">
                        <span translate>
                            cookies-modal.summary
                        </span>
                    </h3>
                    <p translate>cookies-modal.sub-section.part-one</p>
                    <p translate>cookies-modal.sub-section.part-two</p>
                    <p translate>cookies-modal.sub-section.part-three</p>
                    <p translate>cookies-modal.sub-section.part-four</p>
                    <p translate>
                        <span translate>cookies-modal.sub-section.part-five.1</span>
                        <a [href]="showTarteaucitronPopIn()" translate>cookies-modal.sub-section.part-five.2</a>
                        <span translate>cookies-modal.sub-section.part-five.3</span>
                    </p>
                    <p translate>cookies-modal.sub-section.part-six</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
