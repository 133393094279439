<div class="pending-dossier">
    <div class="pending-dossier-container">
        <div class="img text-align-center"><img src="assets/img/redirection.png" alt="redirection logo" class="redirection-logo" /></div>
        <div translate class="lg-text text-align-center title" id="searchTitle">
            waiting-page.thanks
        </div>
        <div class="message">
            <p translate class="sm-md-text text-align-center principal">waiting-page.waiting-verify-email</p>
            <div class="separator">
                <hr />
            </div>
            <p translate class="sm-text text-align-center sub-principal">waiting-page.come-back-home-question</p>
        </div>
        <div class="text-align-center">
            <button type="button" class="btn" translate (click)="goToHome()">
                waiting-page.come-back-home
            </button>
        </div>
    </div>
</div>
