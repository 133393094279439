<div class="manager-header">
    <div class="my-company-card">
        <div class="my-company">
            <div class="my-company-icon">
                <fa-icon [icon]="faBuilding" size="4x" class="pr-4"></fa-icon>
            </div>
            <div class="my-company-infos">
                <h3 class="siret">{{ siret }}</h3>
                <h4 class="name">{{ companyName }}</h4>
                <p class="role">Mandataire</p>
            </div>
        </div>
    </div>
    <button href="#" class="add-user" (click)="inviteUsers($event)">
        <fa-icon icon="user-plus" size="3x"></fa-icon>
        <p translate>inviteUsers</p>
    </button>
</div>
<app-representative-list [representatives]="representatives" [currentUser]="currentUser"></app-representative-list>
<app-rights-holders-manager
    [rightsHolders]="rightsHolders"
    [waitingRightsHolders]="waitingRightsHolders"
    (validate)="validateUser($event)"
    (delete)="removeUser($event)"
></app-rights-holders-manager>

<app-invite-modal [currentUser]="currentUser"></app-invite-modal>
