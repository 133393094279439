import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Modal, ModalService } from '../../../services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { netheos, netheosEventAction, netheosEventSignbook, netheosStatusOfFiles } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { NetheosService } from '../../../services/gdi-api/netheos.service';
import { IsInternalPageService } from '../../../services/is-internal-page.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ProfilCompletionService } from '../../../services/profil-completion-service.service';
import { ApiService } from '../../../services/gdi-api/api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'netheos-associations-modal',
    templateUrl: './netheos-associations-modal.component.html',
    styleUrls: ['./netheos-associations-modal.component.scss']
})
export class NetheosAssociationsModalComponent implements OnInit, OnDestroy {
    @ViewChild('focus') focusElement: ElementRef;
    modalOpen = false;
    faTimes = faTimes;
    identityOK = false;
    accessToken: string;
    openDossierWithLabel: string;
    isCaptchaValidated = false;
    showCaptacha: boolean;
    captcha: any;
    showIframe: boolean;
    netheosUrl: string;
    siret: string;
    _$WorkflowLabel: string;
    _$DossierToken: string;
    @Input()
    siteKey: string;
    content: string;

    subscriptions: Subscription[] = [];

    constructor(
        private modalService: ModalService,
        private auth: AuthService,
        private localStorageService: LocalStorageService,
        private api: NetheosService,
        private apiService: ApiService,
        private isInternalPageService: IsInternalPageService,
        private modal: ModalService,
        private translate: TranslateService,
        private netheosService: NetheosService,
        private profilCompletionService: ProfilCompletionService
    ) {}

    ngOnInit() {
        this.modalService.register(Modal.NetheosAssociations, this);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    init(args: any) {
        this.content = args.messages['utilisateur.non.verifie.association']
            ? args.messages['utilisateur.non.verifie.association']
            : args.messages['utilisateur.a.un.dossier.association'];
        this.isCaptchaValidated = false;
        this.showCaptacha = false;
        this.showIframe = false;
        this.siret = args.siret;
        this.netheosUrl = '';
        this.accessToken = '';
        this._$WorkflowLabel = args.messages._$WorkflowLabel;
        this._$DossierToken = args.messages._$DossierToken;
    }

    initNetheosIframe() {
        if (this._$WorkflowLabel) {
            this.netheosService.createDossier(netheos.authentificationAvecCerfa, this.siret).then(data => {
                this.accessToken = data.messages.accesstoken;
                this.netheosUrl = this.getNetheosUrl(this.accessToken);
                this.showIframe = true;
                if (this.netheosUrl !== '') {
                    this.subscriptions.push(
                        this.netheosService.messageEvent$.subscribe(event => {
                            this.handleNetheosEvents(event);
                        })
                    );
                }
            });
        } else if (this._$DossierToken) {
            this.netheosUrl = this.getNetheosUrl(this._$DossierToken);
            this.showIframe = true;
            if (this.netheosUrl !== '') {
                this.subscriptions.push(
                    this.netheosService.messageEvent$.subscribe(event => {
                        this.handleNetheosEvents(event);
                    })
                );
            }
        }
    }

    getNetheosUrl(accessToken: string) {
        return this.apiService.netheosUrl.concat(netheos.signbook).concat(accessToken);
    }

    handleNetheosEvents(event) {
        // To avoid error in the browser console, we skip the 'unchanged' data
        if (event !== netheosEventSignbook.unchanged) {
            event = JSON.parse(event);

            switch (event.type) {
                case netheosEventSignbook.userEvent:
                    if (event.action === netheosEventAction.exit) {
                        this.showIframe = false;
                        this.hide();
                    }
                    break;

                case netheosEventSignbook.displayEvent:
                    break;

                case netheosEventSignbook.clientFileEvent:
                    // If the documents are ok, go to the validation page
                    if (event.state === netheosStatusOfFiles.accepted) {
                        this.hide();
                    }
                    // If we need a manual validation, hide the iframe and show a message
                    if (event.state === netheosStatusOfFiles.waiting) {
                        this.hide();
                    }
                    break;

                default:
                    break;
            }
        }
    }

    raiseError(error) {
        this.refreshUser();
        console.error('ERROR', error);
        const msg = error.message || this.t('errorCommon');
        this.modal.alert(msg);
    }

    refreshUser() {
        return this.auth.refreshUser();
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    showCaptachaAct() {
        this.showCaptacha = true;
    }

    // Mandatory para Modal Service
    show() {
        this.modalOpen = true;
        setTimeout(() => {
            this.showCaptacha = true;
            this.focusElement.nativeElement.focus();
        }, 0);
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
        this.profilCompletionService.refreshUser();
    }

    onCancel() {}

    cancel() {
        this.onCancel();
        this.modalOpen = false;
        this.profilCompletionService.refreshUser();
    }

    onCaptchaExpiration(captcha: ReCaptcha2Component) {
        captcha.resetCaptcha();
    }

    completeValidation() {
        this.showCaptacha = false;
        this.initNetheosIframe();
    }

    onReCaptcha(status: boolean) {
        this.isCaptchaValidated = status;
    }

    onReCaptchaExpiration(captcha: ReCaptcha2Component) {
        captcha.resetCaptcha();
    }
}
