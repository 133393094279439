<form #projectForm="ngForm" (ngSubmit)="onSubmit()" [class.onerror]="errorState" class="mx-auto">
    <div class="form-group">
        <label for="projet"><span translate>EnterProjectName</span> *</label>
        <br />
        <input
            #projectInput
            #projectNgModel="ngModel"
            (keyup.enter)="onSubmit()"
            [(ngModel)]="project"
            [autofocus]="autofocus"
            (ngModelChange)="formatProjet($event)"
            class="form-control mx-auto"
            id="projet"
            maxlength="100"
            name="projet"
            pattern=""
            placeholder="{{ 'writeProjectName' | translate }}"
            required
            type="text"
        />
        <div *ngIf="errorState">
            <small translate>{{ errorMessage }}</small>
        </div>
    </div>
    <button [disabled]="!projectForm.form.valid" class="btn text-align-center" translate type="submit">
        {{ submitAction }}
    </button>
    <div translate class="text-align-center grey-text">helpAddProject</div>
</form>
