<div class="d-flex flex-column align-items-center">
    <img src="assets/img/erreur.png" class="centered-element page-image" alt="logo" />
    <div class="text-align-center">
        <h3 class="word-break-break-word">{{ subtitle }}</h3>
        <div class="separator">
            <hr />
        </div>
    </div>
    <div class="margin-bottom-20">
        <span class="sm-text">{{ firstMessage }}</span> <span class="sm-text">{{ secondMessage }}</span>
    </div>
    <button type="button" class="btn" (click)="doAction()">
        {{ actionButtonText }}
    </button>
</div>
