<div class="host">
    <!-- Image temporaire en attendant la vraie image -->
    <img src="assets/img/loader.png" class="centered-element page-image" alt="logo" />
    <div>
        <h1 class="text-align-center md-text" translate>{{ isCompany ? 'weSearchCompanies' : 'weSearchProjects' }}</h1>
    </div>
    <div class="separator">
        <hr />
    </div>
    <div class="text-align-center">
        <span translate class="sm-text">bePatient</span>
    </div>
    <div class="text-align-center">
        <img src="/assets/img/loading.svg" class="loader rotate" />
    </div>
</div>
