<div *ngIf="!isLoading">
    <!-- Partie formulaire -->
    <div *ngIf="!showCompanyCard && !hasError">
        <!-- Image temporaire en attendant la vraie image -->
        <div class="image-div">
            <img src="assets/img/entreprise.png" class="page-image centered-element custom-image" alt="" />
        </div>
        <div class="row justify-content-center" id="searchTitle">
            <h1 class="col-12 text-align-center which-company-to-manage" translate="">
                whichCompanyToManage
            </h1>
        </div>
        <div class="row justify-content-center"  style="margin-top: 5px" *ngIf="useNetheos">
            <div class="col-8" >
                <div class="fa fa-info-circle info-badge"></div>
                <div class="description row">
                    <div class="description--one col-6">
                        <span translate>companyRepresentatives</span>
                        <span translate>companyRepresentativesDescription</span>
                    </div>
                    <div class="col-6">
                        <span translate>companyRightHolders</span>
                        <span translate>companyRightHoldersDescription</span>
                    </div>
                </div>
            </div>
        </div>
        <app-form-siret
            [submitAction]="'validate'"
            [autofocus]="true"
            [siret]="siret"
            [errorState]="comingFromBadSiret"
            [errorMessage]="'siretNotFound'"
            (submit)="searchCompany($event)"
        >
        </app-form-siret>
        <!-- <div translate class="text-align-center btn-no-siret">
        <button type="button" class="btn btn-secondary" translate>iDontHaveSiret</button>
      </div> -->
        <div class="text-align-center msg-infos-mandatory" *ngIf="useNetheos">
            <p translate class="grey-text">msgInfodMandatoryDescription</p>
            <p translate class="grey-text">msgInfosMandatory</p>
        </div>
    </div>

    <!-- Partie entreprise trouvée -->
    <div *ngIf="showCompanyCard" class="d-flex flex-column align-items-center">
        <div class="w-100 company-card">
            <app-company-simple-card [siret]="siret" [raisonSociale]="raisonSocialeCompany"> </app-company-simple-card>
        </div>
        <div class="btn-group d-flex flex-column align-items-center">
            <div class="btn-continue">
                <button type="button" class="btn btn-size" translate (click)="connectToCompany()">
                    connectToThisEntity
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-size" translate (click)="resetPage()">
                    notMyCompany
                </button>
            </div>
        </div>
    </div>

    <!-- Partie entreprise non trouvée (l'API est quand même disponible) -->
    <div *ngIf="hasError && !isCompanyApiUnavailable && !showCompanyCard" class="d-flex flex-column align-items-center">
        <img src="assets/img/erreur.png" class="centered-element page-image" alt="" />
        <div class="text-align-center">
            <div style="margin-bottom: 35.6px">
                <h3 class="siret-not-found">{{ errorMessage }}</h3>
            </div>
            <div class="separator">
                <hr />
            </div>
        </div>
        <div class="check-your-entry text-align-center">
            <span class="sm-text" translate>checkYourEntry</span>
        </div>
        <button type="button" class="btn" (click)="resetPage(siret)">
            Vérifier ma saisie
        </button>
    </div>

    <!-- Partie API entreprise indisponible -->
    <app-api-unvailable
        *ngIf="isCompanyApiUnavailable"
        [subtitle]="'apiCompanyUnavailable' | translate"
        [firstMessage]="'tryAgainLater' | translate"
        [actionButtonText]="'goBackToClientApp' | translate"
        (action)="goBackToClientApp()"
    >
    </app-api-unvailable>
</div>

<app-loading-screen *ngIf="isLoading" [isCompany]="true"></app-loading-screen>

<!-- Modale lorsque l'entreprise est exclue de l'application -->
<app-modal [isOpen]="isModalOpen" (close)="resetPage()">
    <p class="content" translate>
        excludedCompaniesMessage
    </p>
    <button (click)="resetPage()" class="btn" translate>validation.otherSiret</button>
</app-modal>

<!-- Modale lors du clic sur crédits sous l'image-->
<app-modal [isOpen]="isCreditModalOpen" (close)="hideCreditModal()">
    <p class="content" translate>
        Illustrations are derivatives of "We are humaaan after all", made by Pablo Stanley (used under
        <a href="https://creativecommons.org/licenses/by/4.0/">CC BY</a>) and from <a href="http://www.freepik.com/">Freepik</a>
    </p>
    <button (click)="hideCreditModal()" class="btn" translate>creditOkButton</button>
</app-modal>
