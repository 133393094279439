import { Component, OnInit } from '@angular/core';
import { ModalService, Modal } from '../../../services/modal/modal.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    modalOpen = false;

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(Modal.Loader, this);
    }

    init() {}

    show() {
        this.modalOpen = true;
    }

    hide() {
        this.modalOpen = false;
    }
}
