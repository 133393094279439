import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { environmentLoader } from '../../../../environments/environmentLoader';

export const ModalAccessibilite = 'accessibilite';

@Component({
    selector: 'app-accessibilite-modal',
    templateUrl: './accessibilite-modal.component.html',
    styleUrls: ['./accessibilite-modal.component.scss']
})
export class AccessibiliteModalComponent implements OnInit {
    modalOpen = false;
    faTimes = faTimes;
    contact = '';

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(ModalAccessibilite, this);
        environmentLoader.then(env => {
            this.contact = env.settings.support;
        });
    }

    // Mandatory para Modal Service
    show() {
        this.modalOpen = true;
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
    }

    onCancel() {}

    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }

    showTarteaucitronPopIn() {
        return window.location + '#tarteaucitron';
    }
}
