import { Attribute } from 'src/app/models/attribute';

export enum EntityTypes {
    Company = 'company',
    Project = 'project',
    TransformedProject = 'transformed_project'
}

export enum EntityRoles {
    Mandataire = 'mandataire',
    AyantDroit = 'ayant-droit',
    EnAttenteAyantDroit = 'en-attente-ayant-droit',
    Porteur = 'porteur',
    LienNonVerifie = 'lien-non-verifie'
}

export class Entity {
    private _name: string;
    private _type: EntityTypes;
    private _role: EntityRoles;
    private _attributes: Attribute[];
    private _isSelected: boolean;

    constructor(name?: string, type?: EntityTypes, role?: EntityRoles, attributes?: Attribute[], isSelected?: boolean) {
        name ? (this._name = name) : (this._name = null);
        type ? (this._type = type) : (this._type = null);
        role ? (this._role = role) : (this._role = null);
        attributes ? (this._attributes = attributes) : (this._attributes = []);
        isSelected ? (this._isSelected = isSelected) : (this._isSelected = false);
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get type(): EntityTypes {
        return this._type;
    }

    set type(value: EntityTypes) {
        this._type = value;
    }

    get role(): EntityRoles {
        return this._role;
    }

    set role(value: EntityRoles) {
        this._role = value;
    }

    get attributes(): Attribute[] {
        return this._attributes;
    }

    set attributes(value: Attribute[]) {
        this._attributes = value;
    }

    get displayName(): string {
        const displayName = this.getAttribute('display-name');
        return displayName ? displayName.values[0] : this._name;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    add(value: Attribute) {
        this._attributes.push(value);
    }

    remove(value: Attribute) {
        this._attributes.splice(this._attributes.indexOf(value), 1);
    }

    getAttribute(attributeName: string): Attribute {
        let result = new Attribute();
        this._attributes.forEach(element => {
            if (element.name === attributeName) {
                result = element;
            }
        });
        return result;
    }

    getRoleLabel(): string {
        switch (this._role) {
            case EntityRoles.LienNonVerifie:
                return "Lien non vérifé avec l'entreprise";
            case EntityRoles.AyantDroit:
                return 'Collaborateur autorisé';
            case EntityRoles.EnAttenteAyantDroit:
                return "Collaborateur en attente d'autorisation";
            case EntityRoles.Mandataire:
                if (this._type === EntityTypes.Company) {
                    return 'Mandataire';
                } else {
                    return 'Porteur de projet';
                }
            default:
                return this._role;
        }
    }
}
