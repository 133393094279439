<div class="manager-card">
    <h2 class="red-title" translate>rightHoldersManagement</h2>
    <div class="info-card">
        <div class="info-card-wrapper">
            <fa-icon [icon]="faInfoCircle" class="pl-2 info-icon" size="2x"></fa-icon>
            <p class="pl-2 pt-3" translate>howToUseRightHoldersManagement</p>
        </div>
    </div>
    <table>
        <thead>
            <tr>
                <th>
                    <h2 translate>waitingRightHolders</h2>
                </th>
                <th></th>
                <th>
                    <h2 translate>rightHolders</h2>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="manager-card-list">
                    <ul class="manager-area-panel">
                        <li *ngFor="let waitingRightHolder of waitingRightsHolders | orderBy: 'lastname'">
                            <div
                                class="user-card user-card-inactive"
                                [ngClass]="{ selected: waitingRightHolder.selected }"
                                role="button"
                                (click)="select($event, waitingRightHolder)"
                            >
                                <button class="close" (click)="close($event, waitingRightHolder)">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </button>
                                <div class="user-card-content">
                                    <fa-icon [icon]="faUserCircle" size="4x"></fa-icon>
                                    <span> {{ waitingRightHolder.firstname }} {{ waitingRightHolder.lastname }} </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </td>
                <td class="manager-card-arrow">
                    <button
                        class="allow-arrow"
                        [ngClass]="{ 'b-selected': selectedWaitingRightHolder }"
                        [disabled]="selectedWaitingRightHolder === null"
                        (click)="allowUser($event)"
                    >
                        <fa-icon icon="caret-right" size="5x"></fa-icon>
                    </button>
                </td>
                <td class="manager-card-list">
                    <ul class="manager-area-panel">
                        <li *ngFor="let rightHolder of rightsHolders | orderBy: 'lastname'">
                            <div class="user-card user-card-active">
                                <button class="close" (click)="close($event, rightHolder)">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </button>
                                <div class="user-card-content">
                                    <fa-icon [icon]="faUserCircle" size="4x"></fa-icon>
                                    <span> {{ rightHolder.firstname }} {{ rightHolder.lastname }} </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>
