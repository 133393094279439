import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/gdi-api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { NetheosService } from '../../services/gdi-api/netheos.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { netheos, netheosEventAction, netheosEventSignbook, netheosStatusOfFiles } from '../../constants';
import { Modal, ModalService } from '../../services/modal/modal.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-netheos-iframe',
    templateUrl: './netheos-iframe.component.html',
    styleUrls: ['./netheos-iframe.component.scss']
})
export class NetheosIframeComponent implements OnInit, OnDestroy {
    @Input()
    accessToken: string;
    @Input()
    workflowLabel: string;
    netheosUrl = '';
    showIframe = false;
    showWaitingMessage = false;
    isLoading = true;
    redirectUri: string;
    user: User;
    isAssociation = 'false';
    @Output()
    documentAccepted = new EventEmitter<any>();

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private authService: AuthService,
        private router: Router,
        private netheosService: NetheosService,
        public localStorageService: LocalStorageService,
        private modal: ModalService
    ) {}

    ngOnInit(): void {
        this.isAssociation = this.localStorageService.getIsAssociation();

        if (this.accessToken && this.accessToken !== '') {
            this.netheosUrl = this.apiService.netheosUrl.concat(netheos.signbook).concat(this.accessToken);
            this.showIframe = true;
        }

        if (this.netheosUrl && this.netheosUrl !== '') {
            this.subscriptions.push(
                this.netheosService.messageEvent$.subscribe(event => {
                    // To avoid error in the browser console, we skip the 'unchanged' data
                    if (event !== netheosEventSignbook.unchanged) {
                        event = JSON.parse(event);

                        switch (event.type) {
                            case netheosEventSignbook.userEvent:
                                if (event.action === netheosEventAction.exit) {
                                    this.modal.hide(Modal.Netheos);
                                }
                                break;

                            case netheosEventSignbook.displayEvent:
                                break;

                            case netheosEventSignbook.clientFileEvent:
                                // If the documents are ok, go to the validation page
                                if (event.state === netheosStatusOfFiles.accepted) {
                                    this.documentAccepted.emit();
                                }
                                // If we need a manual validation, hide the iframe and show a message
                                if (event.state === netheosStatusOfFiles.waiting) {
                                    this.showIframe = false;
                                    this.showWaitingMessage = true;
                                    this.isLoading = false;
                                }
                                break;

                            default:
                                break;
                        }

                        this.isLoading = false;
                    }
                })
            );
        } else {
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subrcription: Subscription) => {
            subrcription.unsubscribe();
        });
    }
}
