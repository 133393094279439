import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';

@Injectable()
export class GroupService extends AbstractService {
    constructor(injector: Injector) {
        super(injector);
        this.endpoint = 'gdi-entreprise/keycloak';
    }

    /**
     * This method adds the user to group of the company thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof GroupService
     */
    joinGroupAndCheckAffiliation(siret: string, params = null) {
        return this.api.post(`${this.endpoint}/joinGroupAndCheckAffiliation/${siret}`, params);
    }

    /**
     * This method create group and sub-groups then add current user to lien-non-verifie sub-group
     *
     * @param {string} siret
     * @memberof GroupService
     */
    createGroup(siret: string, params = null): Promise<any> {
        return this.api.post(`${this.endpoint}/createGroup/${siret}`, params);
    }

    /**
     * This method adds the user to group of the company thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof GroupService
     */
    joinGroupAccessProject(siret: string) {
        return this.api.post(`${this.endpoint}/joinGroup/AccessProject/${siret}`);
    }

    /**
     * This method adds the user to group of the company thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof GroupService
     */
    // joinGroup(siret: string) {
    //   return this.joinGroupWithParams(siret, null);
    // }

    /**
     * This method allows the user to create a project thanks to a project name
     *
     * @param {string} projectName
     * @memberof GroupService
     */
    createProject(projectName: string) {
        return this.api.post(`${this.endpoint}/createProject/${projectName}`);
    }

    /**
     * This method set the default-entity user attribute thanks to a SIRET number or project name
     *
     * @param {string} group
     * @memberof GroupService
     */
    setDefaultGroup(group: string) {
        return this.api.post(`${this.endpoint}/setDefaultGroup/${group}`);
    }

    /**
     * This method remove a group from user profile thanks to a SIRET number or project name
     *
     * @param {string} group
     * @memberof GroupService
     */
    leaveGroup(group: string) {
        return this.api.post(`${this.endpoint}/leaveGroup/${group}`);
    }

    /**
     * This method allows a user who wait for validation thanks to a SIRET number and the user id
     *
     * @param {string} siret
     * @param {string} userIdToValidate
     * @memberof GroupService
     */
    validateEnAttenteDeValidation(siret: string, userIdToValidate: string) {
        return this.api.post(`${this.endpoint}/validateEnAttenteDeValidation/${siret}/${userIdToValidate}`);
    }

    /**
     * This method removes users from roles ayant-droit or en-attente-ayant-droit
     *
     * @param {string} siret
     * @param {string} userId
     * @memberof GroupService
     */
    removeUserFromGroup(siret: string, userId: string) {
        return this.api.post(`${this.endpoint}/removeUserFromGroup/${siret}/${userId}`);
    }

    /**
     * This method remove a group from user profile thanks to a SIRET number or project name
     *
     * @param {string} group
     * @memberof GroupService
     */
    inviteGroup(group: string, recipients: string, message: string) {
        recipients = recipients || 'tdelbart@gmail.com';
        message = message || 'This is my message';
        return this.api.post(`${this.endpoint}/inviteGroup/${group}`, {
            recipients,
            message
        });
    }

    /**
     * This method get all informations of a company thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof GroupService
     */
    getCompany(siret: string) {
        return this.api.get(`${this.endpoint}/getCompany/${siret}`);
    }

    /**
     * This method adds the user to group of the company thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof GroupService
     */
    checkCompanyAffiliation(siret: string, params = null) {
        return this.api.post(`${this.endpoint}/checkCompanyAffiliation/${siret}`, params);
    }
}
