import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalService, Modal } from '../../../services/modal/modal.service';
import { ModalNetheos } from '../netheos-form-modal/netheos-form-modal.component';

@Component({
    selector: 'app-access-error',
    templateUrl: './access-error.component.html',
    styleUrls: ['./access-error.component.scss']
})
export class AccessErrorComponent implements OnInit {
    @ViewChild('focus') focusElement: ElementRef;
    modalOpen = false;
    text: SafeHtml;

    @Input()
    bCheckAffiliation: boolean = false;
    @Input()
    bVerifyIdentity: boolean = false;
    @Input()
    entity: string;
    @Output()
    checkAffiliation = new EventEmitter<object>();

    constructor(private modalService: ModalService, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.modalService.register(Modal.AccessError, this);
    }

    init(text) {
        this.text = this.sanitizer.bypassSecurityTrustHtml(text) || 'Oops!';
    }

    show() {
        this.modalOpen = true;
        setTimeout(() => {
            this.focusElement.nativeElement.focus();
        }, 0);
    }

    hide() {
        this.modalOpen = false;
    }

    checkCompanyAffiliation($event) {
        this.checkAffiliation.emit($event);
    }

    openNetheosModal($event) {
        this.modalService.show(ModalNetheos, { openAccessErrorModal: true });
    }
}
