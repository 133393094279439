import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { GroupService } from '../services/gdi-api/group.service';
import { ModalService } from '../services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../services/local-storage.service';

// ConnectGuard redirect to redirect_uri query parameter
// if connected user has a default entity set
//////////////
@Injectable({
    providedIn: 'root'
})
export class ConnectGuard implements CanActivate {
    constructor(
        protected auth: AuthService,
        protected api: GroupService,
        protected modal: ModalService,
        protected translate: TranslateService,
        private localStorage: LocalStorageService,
        private router: Router
    ) {}

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const redirectUri = next.queryParams['redirect_uri'];
        if (redirectUri && !redirectUri.includes(window.location.host)) {
            this.localStorage.setRedirectUri(redirectUri);
        } else {
            this.localStorage.deleteRedirectUri();
        }
        /* Ce paramètre sert à forcer le navigateur à rester sur la page
         * même si l'utilisateur à un default entity, notamment pour pouvoir choisir une
         * nouvelle défault entity
         */
        const stay = next.queryParams['stay'];
        if (stay) {
            return Promise.resolve(true);
        }

        return this.auth.getUser().then(user => {
            // this.modal.loader(true);
            // this.modal.loader(false);
            return true;
        });
    }

    private goToValidationPage(siret: string) {
        this.localStorage.setSiret(siret);
        this.router.navigate(['/validation'], {
            queryParamsHandling: 'preserve'
        });
    }
}
