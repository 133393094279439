import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ModalService } from '../../services/modal/modal.service';
import { User } from '../../models/user';
import { ProfilCompletionService } from '../../services/profil-completion-service.service';
import { Subscription } from 'rxjs';
import { joinGroupMessages_Pending, joinGroupMessages_Rejected, joinGroupMessages_Waiting } from '../../constants';

@Component({
    selector: 'profil-completion-module',
    templateUrl: './profil-completion-module.component.html',
    styleUrls: ['./profil-completion-module.component.scss']
})
export class ProfilCompletionModuleComponent implements OnInit, OnDestroy {
    currentUser: User;
    percentageCompletion = 0;
    tooltip: string;
    siteKey: string;
    isVerified = false;

    subscritpions: Subscription[] = [];

    constructor(private auth: AuthService, private modal: ModalService, private profilCompletionService: ProfilCompletionService) {}

    ngOnInit(): void {
        this.subscritpions.push(
            this.profilCompletionService.isVerified$.subscribe((isVerified: boolean) => {
                this.isVerified = isVerified;
                this.calculateCompletionPercentage(false);
            })
        );
        this.subscritpions.push(
            this.profilCompletionService.user$.subscribe((user: User) => {
                if (user) {
                    this.currentUser = user;
                    this.currentUser.attributes = user.attributes;
                    this.profilCompletionService.refreshIsVerified();
                    this.calculateCompletionPercentage();
                }
                this.modal.loader(false);
            })
        );
        this.subscritpions.push(
            this.profilCompletionService.netheosIdentityState$.subscribe((messages: string[]) => {
                this.calculateCompletionPercentage();
            })
        );
        setTimeout(() => {
            this.profilCompletionService.refreshIdentityState();
            this.profilCompletionService.refreshUser();
        }, 500);
    }

    ngOnDestroy() {
        this.subscritpions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Calculate completion percentage that will be displayed by the gauge
     */
    calculateCompletionPercentage(refreshUserRole = true) {
        if (refreshUserRole) {
            this.profilCompletionService.refreshIsVerified();
        }
        if (this.isVerified) {
            this.percentageCompletion = 100;
            this.tooltip = 'completion.100';
        } else if (this.profilCompletionService.netheosIdentityState.some(message => joinGroupMessages_Pending.includes(message))) {
            this.percentageCompletion = 75;
            this.tooltip = 'completion.notCompleted';
        } else if (this.profilCompletionService.netheosIdentityState.some(message => joinGroupMessages_Waiting.includes(message))) {
            this.percentageCompletion = 75;
            this.tooltip = 'completion.75';
        } else {
            this.percentageCompletion = 50;
            if (this.profilCompletionService.netheosIdentityState.some(message => joinGroupMessages_Rejected.includes(message))) {
                this.tooltip = 'completion.error';
            } else {
                this.tooltip = 'completion.50';
            }
        }
    }
}
