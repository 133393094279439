import { Component, OnInit, Input } from '@angular/core';
import { ModalService, Modal } from '../../../services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ModalInvite = 'invite';

@Component({
    selector: 'app-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {
    modalOpen = false;
    text: string;
    recipients: string;
    message: string;
    error = true;
    @Input()
    currentUser: User = new User();

    faTimes = faTimes;

    constructor(private modalService: ModalService, protected translate: TranslateService) {}

    ngOnInit() {
        this.modalService.register(ModalInvite, this);
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    // Mandatory para Modal Service
    init(params) {
        if (params && params.onSend) {
            this.onSend = params.onSend;
        }
    }

    onSend(recipients, message) {}
    send(form) {
        this.onSend(form.recipients, form.message);
        this.modalOpen = false;
    }

    // Mandatory para Modal Service
    show() {
        this.message = this.t('defaultInvitationMessage', this.currentUser);
        this.modalOpen = true;
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
    }

    isValidEmailList(event: any) {
        const recipients = event.target.value;
        let isValidEmailList = false;
        if (recipients) {
            isValidEmailList = true;
            const emails = recipients.replace(/\s/g, '').split(/[;,]+/);
            // tslint:disable-next-line:max-line-length
            const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
            emails.forEach(element => {
                if (!regex.test(String(element).toLocaleLowerCase())) {
                    isValidEmailList = false;
                }
            });
        }
        this.error = !isValidEmailList;
        return isValidEmailList;
    }

    onCancel() {}
    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }
}
