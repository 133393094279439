<div>
    <button class="add-entity" (click)="showForm = true" title="Nouveau" *ngIf="!showForm && entities.length > 0" translate>
        <fa-icon icon="plus-square-o" size="4x"></fa-icon>
    </button>
    <div id="entityForm" *ngIf="showForm || entities.length === 0">
        <button (click)="showForm = false" class="close" title="{{ Close | translate }}" *ngIf="entities.length > 0">
            <fa-icon icon="times" size="2x"></fa-icon>
        </button>
        <div *ngIf="typeCompany()" class="company">
            <p>
                <span class="selected option" translate>selectOptionCompany</span>
                <button (click)="toogleEntityType()" class="toggle">
                    <fa-icon icon="faToggleOn" size="3x" rotate="horizontal"></fa-icon>
                </button>
                <span class="option" (click)="toogleEntityType()" translate>selectOptionProject</span>
            </p>
            <form (ngSubmit)="onSubmit('COMPANY', companyCreationForm)" #companyCreationForm="ngForm">
                <div class="form-group">
                    <label for="siret"><span translate>SIRETNumber</span>*</label>
                    <br />
                    <input
                        type="text"
                        class="form-control"
                        id="siret"
                        [(ngModel)]="newSiret"
                        name="siret"
                        required
                        #siret="ngModel"
                        pattern="[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{5}"
                        placeholder="XXX XXX XXX XXXXX"
                        maxlength="17"
                        (keypress)="onlyDigits($event)"
                        (ngModelChange)="formatSiret($event)"
                    />
                </div>
                <button type="submit" class="btn" [disabled]="!companyCreationForm.form.valid" translate>Add</button>
            </form>
            <div class="footer" translate>helpAddCompany</div>
        </div>
        <div *ngIf="typeProject()" class="project">
            <p>
                <span class="option" (click)="toogleEntityType()" translate>selectOptionCompany</span>
                <button (click)="toogleEntityType()" class="toggle">
                    <fa-icon icon="toggle-on" size="3x"></fa-icon>
                </button>
                <span class="selected option" translate>selectOptionProject</span>
            </p>
            <form (ngSubmit)="onSubmit('PROJECT', projectCreationForm)" #projectCreationForm="ngForm">
                <div class="form-group">
                    <label for="name"><span translate>projectName</span>* :</label>
                    <br />
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        [(ngModel)]="newProjectName"
                        name="name"
                        required
                        #name="ngModel"
                        minlength="1"
                        maxlength="50"
                    />
                </div>
                <button type="submit" class="btn" [disabled]="!projectCreationForm.form.valid" translate>Add</button>
            </form>
            <div class="footer" translate>helpAddProject</div>
        </div>
    </div>
</div>
