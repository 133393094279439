import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { endpoints } from '../../constants';
import { AbstractService } from './abstract.service';
import { environmentLoader } from 'src/environments/environmentLoader';

@Injectable()
export class NetheosService extends AbstractService {
    private _messageEvent$ = new Subject<any>();
    private _isEnable = false;

    get messageEvent$() {
        // asObservable() -> permet de retirer la partie "subject" de l'api (next etc...) -> observable en lecture seule
        return this._messageEvent$.asObservable();
    }

    constructor(injector: Injector) {
        super(injector);
        this.endpoint = endpoints.netheos;

        window.addEventListener('message', message => {
            this._messageEvent$.next(message.data);
        });

        environmentLoader.then(env => {
            this._isEnable = env.settings.netheos.enable;
        });
    }

    createDossier(workflowLabel: string, siret: string) {
        return this.api.post(endpoints.netheosCreateDossier, {
            workflowLabel,
            siret
        });
    }

    verifyIdentity() {
        return this.api.post(`${endpoints.netheosVerifyIdentity}`);
    }

    isEnable() {
        return this._isEnable;
    }
}
