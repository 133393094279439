import { Component, Input, OnInit } from '@angular/core';
import { Modal, ModalService } from '../../../services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { joinGroupMessages_IdentityFail, netheos } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { NetheosService } from '../../../services/gdi-api/netheos.service';
import { IsInternalPageService } from '../../../services/is-internal-page.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ProfilCompletionService } from '../../../services/profil-completion-service.service';
import { Subscription } from 'rxjs';

export const ModalNetheos = 'netheos';

@Component({
    selector: 'netheos-form-modal',
    templateUrl: './netheos-form-modal.component.html',
    styleUrls: ['./netheos-form-modal.component.scss']
})
export class NetheosFormModalComponent implements OnInit {
    modalOpen = false;
    faTimes = faTimes;
    isCompanyApiUnvailable = false;
    identityOK = false;
    identityFailed = false;
    openDossierWithToken: string;
    accessToken: string;
    openDossierWithLabel: string;
    isCaptcha1Validated = false;
    showIframe: boolean;
    showReCaptcha: boolean;
    @Input()
    siteKey: string;
    showAccessErrorModal: boolean;

    subscriptions: Subscription[] = [];

    constructor(
        private modalService: ModalService,
        private auth: AuthService,
        private localStorageService: LocalStorageService,
        private api: NetheosService,
        private isInternalPageService: IsInternalPageService,
        private modal: ModalService,
        private translate: TranslateService,
        private netheosService: NetheosService,
        private profilCompletionService: ProfilCompletionService
    ) {}

    ngOnInit() {
        this.modalService.register(ModalNetheos, this);
        this.showIframe = false;
        this.identityOK = false;
        this.showReCaptcha = true;
        this.profilCompletionService.refreshReCaptcha();
    }

    init(args?: any) {
        this.showAccessErrorModal = args && args.openAccessErrorModal;
        this.showIframe = false;
        this.identityOK = false;
        this.showReCaptcha = true;
    }

    computeJoinResponse(response) {
        if (response.messages && Object.keys(response.messages).length) {
            if (response.messages._$WorkflowLabel) {
                this.openDossierWithLabel = response.messages._$WorkflowLabel;

                if (this.openDossierWithLabel === netheos.authentificationAvecCerfa) {
                    this.localStorageService.setIsAssociation('true');
                }
                if (
                    this.openDossierWithLabel === netheos.authentificationSeule ||
                    this.openDossierWithLabel === netheos.authentificationAvecCerfa
                ) {
                    this.netheosService
                        .createDossier(
                            this.openDossierWithLabel,
                            this.localStorageService.getProjectId()
                                ? this.localStorageService.getProjectId()
                                : this.localStorageService.getSiret()
                        )
                        .then(data => {
                            if (data && data.messages && data.messages.accesstoken) {
                                this.accessToken = data.messages.accesstoken;
                            }
                        });
                } else {
                    // Pas le bon workflowlabel donc sert a rien d'aller plus loin... On fait quoi ?
                }
            }

            if (response.messages._$DossierToken) {
                this.openDossierWithToken = response.messages._$DossierToken;
                this.accessToken = this.openDossierWithToken;
            }

            let messages = Object.keys(response.messages)
                .map((key: string) => {
                    // Check list status
                    if (joinGroupMessages_IdentityFail.indexOf(key) > -1) {
                        this.showReCaptcha = false;
                        this.identityOK = false;
                        this.identityFailed = true;
                    }

                    // Return message
                    const message = response.messages[key];
                    return key.indexOf('_$') !== 0 ? message.replace('. ', '.<br/>') : null;
                })
                .filter(v => v !== null);

            if (this.identityFailed) {
                // WHen identity failed, this means link was OK
                this.identityOK = false;
                this.showReCaptcha = true;
            }
        } else {
            this.identityOK = true;
            this.showReCaptcha = false;
        }
    }

    raiseError(error) {
        this.refreshUser();
        console.error('ERROR', error);
        const msg = error.message || this.t('errorCommon');
        this.modal.alert(msg);
    }

    refreshUser() {
        return this.auth.refreshUser();
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    // Mandatory para Modal Service
    show() {
        this.showIframe = false;
        this.identityOK = false;
        this.showReCaptcha = true;
        this.modalOpen = true;
        this.profilCompletionService.forceVerify = true;
        this.profilCompletionService.refreshReCaptcha();

        if (this.api.isEnable()) {
            this.api
                .verifyIdentity()
                .then(response => this.computeJoinResponse(response))
                .catch(error => {
                    if (error.status === 404 && error.error.message === 'Le service public API Entreprise ne répond pas.') {
                        this.isCompanyApiUnvailable = true;
                    } else {
                        if (error.error) {
                            this.raiseError(error.error);
                        } else {
                            this.raiseError(error);
                        }
                    }
                });
        }
    }

    // Mandatory para Modal Service
    hide() {
        if (this.netheosService.isEnable()) {
            this.netheosService.verifyIdentity().then(() => {
                this.profilCompletionService.refreshUser();
                this.profilCompletionService.refreshIdentityState();
            });
        }
        this.modalOpen = false;
        if (this.showAccessErrorModal) {
            this.modalService.show(Modal.AccessError);
        }
    }

    onCancel() {}

    cancel() {
        this.onCancel();
        this.modalOpen = false;
        if (this.netheosService.isEnable()) {
            this.netheosService.verifyIdentity().then(() => {
                this.profilCompletionService.refreshUser();
                this.profilCompletionService.refreshIdentityState();
            });
        }
        if (this.showAccessErrorModal) {
            this.modalService.show(Modal.AccessError);
        }
    }

    onCaptchaExpiration(captcha: ReCaptcha2Component) {
        captcha.resetCaptcha();
    }

    nextStep() {
        this.showIframe = true;
        this.isCaptcha1Validated = true;
    }

    checkedIdentity() {
        this.identityFailed = false;
        this.identityOK = true;
        this.showReCaptcha = false;
        this.showIframe = false;
        this.profilCompletionService.refreshUser();
        if (this.netheosService.isEnable()) {
            this.netheosService.verifyIdentity().then(data => {
                this.computeJoinResponse(data);
            });
        }
    }
}
