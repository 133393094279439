import { Component, OnInit } from '@angular/core';
import { ModalService, Modal } from '../../../services/modal/modal.service';

@Component({
    selector: 'app-alert-action',
    templateUrl: './alert-action.component.html'
})
export class AlertActionComponent implements OnInit {
    modalOpen = false;
    text: string;
    buttonText: string;

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(Modal.AlertAction, this);
    }

    init(params) {
        if (params) {
            this.text = params.text || 'Oops!';
            this.buttonText = params.buttonText || 'OK';
            if (params.onSuccess) {
                this.onSuccess = params.onSuccess;
            }
            if (params.onCancel) {
                this.onCancel = params.onCancel;
            }
        }
    }

    onSuccess() {}
    doAction() {
        this.onSuccess();
        this.modalOpen = false;
    }

    onCancel() {}
    cancel() {
        this.onSuccess();
        this.modalOpen = false;
    }

    show() {
        this.modalOpen = true;
    }

    hide() {
        this.onSuccess();
        this.modalOpen = false;
    }
}
