import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Entity } from '../../models/entity';
import { Router } from '@angular/router';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { ProfilCompletionService } from '../../services/profil-completion-service.service';
import { Subscription } from 'rxjs';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-entity-manager-access',
    templateUrl: './entity-manager-access.component.html',
    styleUrls: ['./entity-manager-access.component.scss']
})
export class EntityManagerAccessComponent implements OnInit, OnDestroy {
    @Input()
    entity: Entity;
    isMandataire: boolean;
    isVerified: boolean;
    faUserCog = faUsersCog;

    subscriptions: Subscription[] = [];

    constructor(private router: Router, private profilCompletionService: ProfilCompletionService, private auth: AuthService) {}

    ngOnInit(): void {
        this.isMandataire = this.entity.getRoleLabel() === 'Mandataire';
        this.subscriptions.push(
            this.profilCompletionService.user$.subscribe((user: User) => {
                this.isVerified = this.auth.getUserRoles().includes('VERIFIED');
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    accessToManager($event) {
        $event.stopPropagation();
        if (this.entity.type === 'company') {
            this.router.navigateByUrl(`/gestion/${this.entity.name}?redirect_uri=${window.location.href}`);
        }
    }
}
