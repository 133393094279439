<!-- Partie validation en cours -->
<div *ngIf="!hasMessages && !isCompanyApiUnvailable">
    <!-- Image temporaire en attendant la vraie image -->
    <img src="assets/img/loader.png" class="centered-element page-image" alt="logo" />
    <div>
        <h2 *ngIf="!showContinue" class="text-align-center md-text" translate>
            validation.inProgress
        </h2>
        <h2 *ngIf="showContinue" class="text-align-center md-text" translate>
            validation.successfull
        </h2>
    </div>
    <div class="separator">
        <hr />
    </div>
    <div *ngIf="!showContinue" class="text-align-center">
        <span translate class="sm-text">validation.bePatient</span>
    </div>
    <div>
        <ul class="check-list">
            <li>
                <img *ngIf="!showContinue && !identityOK" src="/assets/img/loading.svg" class="loader rotate" alt="Chargement" />
                <img *ngIf="showContinue || identityOK" src="/assets/img/check-icon.svg" alt="OK" />
                <span translate>validation.checkIdentity</span>
            </li>
            <li *ngIf="!this.localStorageService.getProjectId()">
                <img *ngIf="!showContinue && !linkOK" src="/assets/img/loading.svg" class="loader rotate" alt="Chargement" />
                <img *ngIf="showContinue || linkOK" src="/assets/img/check-icon.svg" alt="OK" />
                <span translate>validation.checkLink</span>
            </li>
        </ul>
    </div>
    <div *ngIf="showContinue && redirectUri" class="text-align-center">
        <a class="btn" [href]="redirectUri.includes(hubUri) ? redirectUri : hubUri" translate>
            validation.continueOnHub
        </a>
    </div>
</div>

<!-- Partie has error -->
<div *ngIf="hasMessages && !isCompanyApiUnvailable" class="d-flex flex-column align-items-center">
    <img src="assets/img/erreur.png" class="centered-element page-image" alt="logo" />
    <div class="text-align-center">
        <h3 class="word-break-break-word" translate>validation.hasMessages</h3>
        <div class="separator">
            <hr />
        </div>
        <ul class="message-list">
            <li *ngFor="let message of messages" [innerHTML]="message"></li>
        </ul>
        <ul class="check-list">
            <li>
                <img *ngIf="identityOK" src="/assets/img/check-icon.svg" alt="OK" />
                <img *ngIf="!identityOK" src="/assets/img/alert-icon.svg" alt="KO" />
                <span translate>validation.checkIdentity</span>
            </li>
            <li *ngIf="!this.localStorageService.getProjectId()">
                <img *ngIf="linkOK" src="/assets/img/check-icon.svg" alt="OK" />
                <img *ngIf="!linkOK" src="/assets/img/alert-icon.svg" alt="KO" />
                <span translate>validation.checkLink</span>
            </li>
        </ul>
    </div>
    <div class="margin-bottom-20" *ngIf="openDossierWithToken">
        <ngx-recaptcha2
            #captchaElem1
            name="captcha1"
            [siteKey]="siteKey"
            [size]="'normal'"
            (expire)="onCaptchaExpiration(captchaElem1)"
            (success)="isCaptcha1Validated = true"
            [(ngModel)]="captcha1"
            [ngModelOptions]="{ standalone: true }"
        >
        </ngx-recaptcha2>
        <p>
            <a
                class="btn "
                [routerLink]="['/uploadFile', openDossierWithToken]"
                queryParamsHandling="preserve"
                translate
                [class.disabled]="!isCaptcha1Validated"
                >validation.completeDossier</a
            >
        </p>
        <p><a class="btn" (click)="goBackToClientApp()" translate>waiting-page.come-back-home</a></p>
    </div>
    <div class="margin-bottom-20" *ngIf="openDossierWithLabel">
        <ngx-recaptcha2
            #captchaElem2
            name="captcha2"
            [siteKey]="siteKey"
            [size]="'normal'"
            (success)="isCaptcha2Validated = true"
            (expire)="onCaptchaExpiration(captchaElem2)"
            [(ngModel)]="captcha2"
            [ngModelOptions]="{ standalone: true }"
        >
        </ngx-recaptcha2>

        <p>
            <a
                class="btn"
                [routerLink]="['/uploadFile/type', openDossierWithLabel]"
                queryParamsHandling="preserve"
                translate
                [class.disabled]="!isCaptcha2Validated"
                >validation.completeDossier</a
            >
        </p>
        <p><a class="btn" (click)="goBackToClientApp()" translate>waiting-page.come-back-home</a></p>
    </div>
    <div class="margin-bottom-20" *ngIf="!openDossierWithToken && !openDossierWithLabel">
        <div class="separator">
            <hr />
        </div>
        <p class="sm-text">
            <a class="btn" [routerLink]="['/entreprise']" queryParamsHandling="preserve" translate>validation.newSiret</a>
        </p>
    </div>
</div>

<app-api-unvailable
    *ngIf="isCompanyApiUnvailable"
    [subtitle]="'apiCompanyUnavailable' | translate"
    [firstMessage]="'tryAgainLater' | translate"
    [actionButtonText]="'goBackToClientApp' | translate"
    (action)="goBackToClientApp()"
>
</app-api-unvailable>
