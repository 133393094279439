import { Component, Input, OnInit } from '@angular/core';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { environmentLoader } from '../../../environments/environmentLoader';
import { AuthService } from '../../services/auth/auth.service';
import { Modal, ModalService } from '../../services/modal/modal.service';

@Component({
    selector: 'profil-completion-actions',
    templateUrl: './profil-completion-actions.component.html',
    styleUrls: ['./profil-completion-actions.component.scss']
})
export class ProfilCompletionActionsComponent implements OnInit {
    public faUserEdit = faUserEdit;
    public faCheckCircle = faCheckCircle;
    protected keycloakUrl: string;
    protected keycloakRealm: string;
    @Input()
    public isVerified;

    constructor(private auth: AuthService, private modalService: ModalService) {}

    ngOnInit(): void {
        environmentLoader.then(env => {
            this.keycloakUrl = env.settings.auth.keycloak.url;
            this.keycloakRealm = env.settings.auth.keycloak.realm;
        });
    }

    goToEditProfile() {
        return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account?referrer=${window.location}`;
    }

    openNetheosModal($event) {
        this.modalService.show(Modal.Netheos);
    }
}
