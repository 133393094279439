<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>accessibilite-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <h6 class="lv1"><span>Déclaration d'accessibilité</span></h6>
                    <p>La Région Occitanie s’engage à rendre le site Mon compte Entreprise accessible conformément à l’article 47 de la loi n° 2005-102 du 11 février 2005.</p>
                    <p>A cette fin, la Région Occitanie élabore actuellement un schéma pluriannuel.</p>
                    <p>Cette déclaration d’accessibilité s’applique au site Mon compte Entreprise.</p>
                    <h6 class="lv2">État de conformité</h6>
                    <p>Le site Mon compte Entreprise est en partielle conformité avec les critères du <a href="https://www.numerique.gouv.fr/publications/rgaa-accessibilite/" class="pointer" target="_blank">référentiel RGAA 4 (Ouverture dans un nouvel onglet)</a>.</p>
                    <h6 class="lv3">Détails des non-conformités</h6>
                    <ul>
                        <li>Les informations sont restituées de façon illisibles lorsque les feuilles de style sont désactivées. Dû aux spécificités d'Angular ;</li>
                        <li>Les informations ne sont pas restituées lorsque le javascript est désactivée. Dû aux spécificités d'Angular ;</li>
                    </ul>

                    <h6 class="lv3">Dérogations</h6>
                    <h6 class="lv4">Motifs de dérogation et éléments concernés</h6>
                    <h6 class="lv5">Composants et contenus tiers</h6>
                    <p>Les composants et contenus suivants émanent d'entités tierces, ni contrôlées ni financées par la Région. Nous ne pouvons donc pas en garantir la conformité au RGAA.</p>
                    <ul>
                        <li>Certaines images, fournies par des tiers, comportent des textes dont le contraste est insuffisant ;</li>
                        <li>L'assistant numérique  TarteAuCitron, utilisé pour la gestion des cookies.</li>
                    </ul>

                    <h6 class="lv2">Établissement de cette déclaration d'accessibilité</h6>

                    <p>Cette déclaration a été établie le 02 novembre 2020 en RGAA 3. Puis, elle a été mise à jour le 26 janvier 2021 pour correspondre au RGAA 4.</p>

                    <p>Technologies utilisées pour la réalisation du site Web :</p>

                    <ul>
                        <li>Keycloak</li>
                        <li>Angular</li>
                        <li>Java Springboot</li>
                    </ul>

                    <h6 class="lv2">Agents utilisateurs et outils utilisés pour vérifier l’accessibilité</h6>

                    <p>Les tests des pages web ont été effectués avec les navigateurs web suivants :</p>
                    <ul>
                        <li>Google chrome</li>
                        <li>Firefox</li>
                        <li>Google chrome (Android)</li>
                    </ul>

                    <p>Les outils suivants ont été utilisés lors de l’évaluation :</p>

                    <ul>
                        <li><a href="https://asqatasun.org/" target="_blank">Asqatasun (Ouverture dans un nouvel onglet)</a></li>
                        <li><a href="https://chrome.google.com/webstore/detail/wcag-color-contrast-check/plnahcmalebffmaghcpcmpaciebdhgdf" target="_blank">WCAG Color contrast checker (Ouverture dans un nouvel onglet)</a></li>
                        <li><a href="https://chrome.google.com/webstore/detail/disable-html/lfhjgihpknekohffabeddfkmoiklonhm" target="_blank">Disable-HTML (Ouverture dans un nouvel onglet)</a></li>
                        <li><a href="https://chrome.google.com/webstore/detail/assistant-rgaa/cgpmofepeeiaaljkcclfldhaalfpcand?hl=fr" target="_blank">Assistant RGAA (Ouverture dans un nouvel onglet)</a></li>
                    </ul>

                    <p>Pages du site ayant fait l’objet de la vérification de conformité</p>
                    <ul>
                        <li>Page de connexion</li>
                        <li>Page édition de mot de passe</li>
                        <li>Page réinitialisation de mot de passe</li>
                        <li>Page édition des préférences</li>
                        <li>Page mon compte</li>
                        <li>Page ajout de mon entreprise</li>
                        <li>Page ajout de mon projet</li>
                        <li>Page de gestion des collaborateurs</li>
                        <li>Page d'inscription</li>
                    </ul>


                    <h6 class="lv2">Retour d'information et contact</h6>

                    <p>Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable du site internet pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme à l'adresse suivante :
                        <a [href]="'mailto:Support%20Hub%20Entreprendre<' + contact + '>?subject=[Mon%20Compte%20Entreprise%20Accésibilité]'">
                            <span>{{ contact }}</span>
                        </a>

                    <h6 class="lv2">Voies de recours</h6>

                    <p>Cette procédure est à utiliser dans le cas suivant.</p>

                    <p>Vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du portail et vous n’avez pas obtenu de réponse satisfaisante.</p>
                    <ul>
                        <li>Écrire un message au Défenseur des droits (https://formulaire.defenseurdesdroits.fr/)</li>
                        <li>Contacter le délégué du Défenseur des droits dans votre région ( https://www.defenseurdesdroits.fr/saisir/delegues)</li>
                        <li>Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) :<br/>
                            Défenseur des droits<br/>
                            Libre réponse 71120<br/>
                            75342 Paris CEDEX 07
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
