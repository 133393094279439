import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { GroupService } from '../../services/gdi-api/group.service';
import { ProjectService } from '../../services/gdi-api/project.service';
import { environmentLoader } from '../../../environments/environmentLoader';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NetheosService } from '../../services/gdi-api/netheos.service';
import { joinGroupMessages_ActionNotAble, joinGroupMessages_IdentityFail } from '../../constants';
import { ProfilCompletionService } from '../../services/profil-completion-service.service';
import { Subscription } from 'rxjs';
import { Modal, ModalService } from '../../services/modal/modal.service';

@Component({
    selector: 'netheos-form',
    templateUrl: './netheos-form.component.html',
    styleUrls: ['./netheos-form.component.scss']
})
export class NetheosFormComponent implements OnInit, OnDestroy {
    redirectUri: string;
    accountUri: string;
    hubUri: string;
    hasMessages = false;
    hasError = false;
    messages: string[] = [];
    siret: any;
    project: any;
    isCompanyApiUnvailable = false;
    captcha1: any;
    recaptcha: ReCaptcha2Component;
    isCaptcha1Validated = false;
    identityFailed = false;
    show = false;
    @Input()
    identityOK: boolean;
    @Input()
    siteKey: boolean;
    @Input()
    showReCaptcha: boolean;
    @Output()
    nextStep = new EventEmitter();

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private api: GroupService,
        private apiNetheos: NetheosService,
        private projectApi: ProjectService,
        private auth: AuthService,
        public localStorageService: LocalStorageService,
        private netheosService: NetheosService,
        private profilCompletionService: ProfilCompletionService,
        private modal: ModalService
    ) {
        this.subscriptions.push(
            this.route.queryParams.subscribe(params => {
                this.redirectUri = params['redirect_uri'] ? params['redirect_uri'] : this.localStorageService.getRedirectUri();
            })
        );
        environmentLoader.then(env => {
            this.accountUri = `${env.settings.auth.keycloak.url}/realms/${env.settings.auth.keycloak.realm}/account?referrer=${document.location}`;
            this.hubUri = `${env.settings.hub.url}`;
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.profilCompletionService.forceVerify$.subscribe((status: boolean) => {
                if (status && this.apiNetheos.isEnable()) {
                    this.apiNetheos.verifyIdentity().then(response => this.computeJoinResponse(response));
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subsciption: Subscription) => {
            subsciption.unsubscribe();
        });
    }

    computeJoinResponse(response) {
        if (response.messages && Object.keys(response.messages).length) {
            this.messages = Object.keys(response.messages)
                .map((key: string) => {
                    // Check list status
                    if (joinGroupMessages_IdentityFail.indexOf(key) > -1) {
                        if (joinGroupMessages_ActionNotAble.indexOf(key) > -1) {
                            this.showReCaptcha = false;
                            this.hasError = true;
                        } else {
                            this.showReCaptcha = true;
                            this.hasError = false;
                        }
                        this.identityOK = false;
                        this.identityFailed = true;
                    }

                    // Return message
                    const message = response.messages[key];
                    return key.indexOf('_$') !== 0 ? message.replace('. ', '.<br/>') : null;
                })
                .filter(v => v !== null);

            if (this.identityFailed) {
                // WHen identity failed, this means link was OK
                this.identityOK = false;
            }
        } else {
            this.identityOK = true;
            this.showReCaptcha = false;
        }
    }

    goBackToClientApp() {
        this.subscriptions.push(
            this.route.queryParams.subscribe((params: Params) => {
                this.redirectUri = params['redirect_uri'] ? params['redirect_uri'] : this.hubUri;
                this.auth.logout(this.redirectUri);
            })
        );
    }

    onLoadCaptcha(captcha: ReCaptcha2Component) {
        this.recaptcha = captcha;
    }

    onReCaptcha(status: boolean) {
        this.isCaptcha1Validated = status;
    }

    onReCaptchaExpiration(captcha: ReCaptcha2Component) {
        captcha.resetCaptcha();
    }

    hide() {
        this.modal.hide(Modal.Netheos);
    }

    completeFolder() {
        this.recaptcha.resetCaptcha();
        this.nextStep.emit();
    }
}
