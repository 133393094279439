<footer id="footer" class="occ-footer  footer">
        <div id="footer-wrapper" role="navigation" class="row justify-content-center">
            <div class="col-md-auto my-auto text-center">
                <a (click)="openCguModal($event)"  href="javascript:void(0)">
                    <span translate>footer.cgu</span>
                </a>
            </div>
            <div class="col-md-auto my-auto text-center">
                <a (click)="openRgpdModal($event)" href="javascript:void(0)">
                    <span translate>footer.rgpd</span>
                </a>
            </div>
            <div class="col-md-auto my-auto text-center">
                <a (click)="openCookiesModal($event)" href="javascript:void(0)">
                    <span translate>footer.cookies</span>
                </a>
            </div>
            <div class="col-md-auto my-auto text-center">
                <a (click)="openAccessibiliteModal($event)" href="javascript:void(0)">
                    <span translate>footer.accessibility</span>
                </a>
            </div>
            <div class="col-md-auto my-auto text-center">
                <a (click)="openPlanDuSiteModal($event)" href="javascript:void(0)">
                    <span translate>footer.sitemap</span>
                </a>
            </div>
            <div class="col-md-auto my-auto text-center">
                <a [href]="'mailto:' + contact + '?subject=[Mon%20Compte%20Entreprise]'">
                    <span translate>footer.contact</span>
                </a>
            </div>
        </div>
</footer>
