import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const Modal = {
    AccessError: 'accessError',
    Alert: 'alert',
    AlertAction: 'alertAction',
    Confirm: 'confirm',
    Loader: 'loader',
    NetheosAssociations: 'netheosAssociations',
    Netheos: 'netheos'
};

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    modals: any[] = [];
    showLoader = new BehaviorSubject(false);
    constructor() {}

    /* Generic methods */

    show(name: string, args?: any) {
        console.log('show(): name ', this.modals[name]);
        if (this.modals[name]) {
            if (args) {
                this.modals[name].init(args);
            }
            this.modals[name].show();
        } else {
            console.error('No modal ' + name + ' found');
        }
    }

    hide(name: string) {
        if (this.modals[name]) {
            this.modals[name].hide();
        } else {
            console.error('No modal ' + name + ' found');
        }
    }

    register(name: string, modal) {
        console.log('register(): name modal ', modal);
        console.log('register(): modal  ', modal);
        if (modal.show && modal.hide) {
            this.modals[name] = modal;
        } else {
            console.error('Modal ' + name + ' has no mandatory show and hide methods');
        }
    }

    /* Modal specific method */

    accessError(args) {
        this.show(Modal.AccessError, args);
    }

    alert(args) {
        this.show(Modal.Alert, args);
    }

    alertAction(args) {
        this.show(Modal.AlertAction, args);
    }

    confirm(args) {
        this.show(Modal.Confirm, args);
    }

    loader(show: boolean) {
        if (show) {
            this.showLoader.next(true);
            this.show(Modal.Loader);
        } else {
            this.showLoader.next(false);
            this.hide(Modal.Loader);
        }
    }

    netheos(args) {
        this.show(Modal.Netheos, args);
    }

    netheosAssociations(args?) {
        this.show(Modal.NetheosAssociations, args);
    }
}
