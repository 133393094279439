<div *ngIf="entities.length > 0">
    <ul class="entity-list">
        <li *ngFor="let entity of entities | sortEntity">
            <div class="row pb-3">
                <div class="col-11 entity-card" [attr.aria-label]="
                                        (entity.type === 'transformed_project'
                                                    ? entity.getAttribute('projectName').values[0]
                                                    : entity.getAttribute('display-name').values[0]
                                                )| truncate: 50
                                            " 
                                            [ngClass]="{'selection': entity.isSelected}"
                                            role="button" (click)="selectEntity(entity)" (keyup.enter)="selectEntity(entity)" (keyup.space)="selectEntity(entity)" tabindex="0">
                    <div class="row">
                        <div class="col entity-card-header">
                            <div class="row">
                                <div class="col-lg-1 col-md-1 col-sm-2 pl-2 pb-2">
                                    <img
                                        class="default-logo-company"
                                        [src]="getDefaultLogoImage(entity)"
                                        [alt]="(entity.type === 'company' ? 'alt.companyType' : 'alt.projectType') | translate"
                                    />
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <label class="col entity-card-header-name pt-1">
                                            {{
                                                (entity.type === 'transformed_project'
                                                    ? entity.getAttribute('projectName').values[0]
                                                    : entity.getAttribute('display-name').values[0]
                                                ) | truncate: 50
                                            }}
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="col entity-card-header-role" *ngIf="useApiEntreprise">
                                            <i *ngIf="isLienNonVerifieRole(entity); else other">{{ entity.getRoleLabel() }}</i>
                                            <ng-template #other>
                                                {{ entity.getRoleLabel() }}
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col entity-card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div
                                            *ngIf="isCompany(entity)"
                                            [class]="isCompany(entity) ? 'col pt-1-5' : 'col-lg col-md col-sm-7 pt-1'"
                                        >
                                            {{ entity.name }}
                                        </div>
                                        <div
                                            [class]="
                                                isCompany(entity)
                                                    ? 'col-5 text-right pt-1 pb-1'
                                                    : 'col-lg col-md text-right pt-1 pb-1'
                                            "
                                        >
                                            <app-entity-manager-access
                                                *ngIf="useApiEntreprise"
                                                [entity]="entity"
                                            ></app-entity-manager-access>
                                            <app-check-company-affiliation
                                                *ngIf="isCompany(entity) && useApiEntreprise"
                                                (checkAffiliation)="checkCompanyAffiliation($event)"
                                                [entity]="entity"
                                            ></app-check-company-affiliation>
                                            <button
                                                *ngIf="isCompany(entity)"
                                                (click)="removeEntity($event, entity)"
                                                placement="bottom"
                                                ngbTooltip="{{ 'Remove' | translate }}"
                                                class="link-custom-focus entity-card-body-delete"
                                                [attr.aria-label]="'alt.removeCompany' | translate"
                                            >
                                                <span><fa-icon [icon]="faTrash" size="1x"></fa-icon></span>
                                            </button>
                                            <div *ngIf="!isCompany(entity)">
                                                <button
                                                    *ngIf="!isTransformedProject(entity)"
                                                    placement="bottom"
                                                    ngbTooltip="{{ 'declareCompany' | translate }}"
                                                    (click)="goToCompanySiretSearchPage(entity.name)"
                                                    class="link-custom-focus entity-card-body-declare"
                                                    [attr.aria-label]="'declareCompany' | translate"
                                                >
                                                    <span translate>
                                                        declareCompany
                                                        <fa-icon [icon]="faRocket" size="1x" class="pl-1"></fa-icon>
                                                    </span>
                                                </button>
                                                <div
                                                    *ngIf="isTransformedProject(entity)"
                                                    class="link-custom-focus entity-card-body-declared"
                                                    [attr.aria-label]="'companyCreated' | translate"
                                                    disabled
                                                >
                                                    <span translate>
                                                        companyCreated
                                                        <fa-icon [icon]="faCheckCircle" size="1x" class="pl-1"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
