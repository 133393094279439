import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProjectService } from '../../services/gdi-api/project.service';
import { IsInternalPageService } from '../../services/is-internal-page.service';
import { AuthService } from '../../services/auth/auth.service';
import { ModalService } from '../../services/modal/modal.service';
import { GroupService } from '../../services/gdi-api/group.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
    project = '';
    hasError = false;
    showProjectCard = false;
    isLoading = false;
    isProjectNameAvailable = false;
    comingFromBadSiret = false;
    errorMessage = '';
    redirectUri: string;
    isModalOpen = false;
    isCreditModalOpen = false;

    subscritpions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private localStorageService: LocalStorageService,
        private isInternalPageService: IsInternalPageService,
        private modal: ModalService,
        private groupService: GroupService
    ) {}

    ngOnInit() {
        if (this.localStorageService.getSiret()) {
            this.localStorageService.deleteSiret();
        }
        this.route.queryParams.subscribe(params => {
            this.redirectUri = params['redirect_uri'];
        });
        this.isInternalPageService.changeValue(true);
    }

    ngOnDestroy() {
        this.subscritpions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    hideCreditModal() {
        this.isCreditModalOpen = false;
    }

    showCreditModal() {
        this.isCreditModalOpen = true;
    }

    searchProject(project: string) {
        this.isLoading = true;
        this.showProjectCard = false;
        this.comingFromBadSiret = false;
        this.authService.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                this.projectService
                    .createProject(project)
                    .then((response: any) => {
                        this.localStorageService.setProjectName(response['project-name']);
                        this.localStorageService.setProjectId(response['uuid']);
                        this.hasError = false;
                        this.isLoading = false;
                        this.connectToProject();
                    })
                    .catch((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            this.errorMessage = error.error.message;
                        } else if (error.status === 409) {
                            this.errorMessage = error.error.message;
                        }
                        this.hasError = true;
                        this.isLoading = false;
                        this.isProjectNameAvailable = error.status !== 409;
                        this.showProjectCard = false;
                    });
            } else {
                this.hasError = true;
                this.isLoading = false;
                this.modal.alertAction({
                    text: this.t('sessionExpired'),
                    onSuccess: () => {
                        this.router.navigate(['/connect'], {
                            queryParamsHandling: 'preserve'
                        });
                    }
                });
            }
        });
    }

    /**
     * Resets the workflow of this page with an optional SIRET to begin with
     * @param {string} siret siret
     */
    resetPage(siret = '') {
        this.hasError = false;
        this.isModalOpen = false;
        this.isProjectNameAvailable = false;
        this.showProjectCard = false;
        this.localStorageService.setCompany('undefined');
        if (siret) {
            this.comingFromBadSiret = true;
        }
    }

    connectToProject() {
        this.groupService
            .joinGroupAndCheckAffiliation(this.localStorageService.getProjectId())
            .catch(error => {})
            .then(response => {
                if (response.messages) {
                    this.router.navigate(['/'], {
                        queryParamsHandling: 'preserve'
                    });
                }
            });
    }

    continueWorkflow() {
        // Saves in localStorage
        if (this.localStorageService.getProjectName() === '') {
            this.localStorageService.setProjectName(this.project);
        }
        this.router.navigate(['/validation'], {
            queryParamsHandling: 'preserve'
        });
    }

    goBackToClientApp() {
        window.location.href = this.localStorageService.getRedirectUri();
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }
}
