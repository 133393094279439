<div class="upload-file">
    <div class="upload-file-container">
        <div class="no-waiting" *ngIf="!showWaitingMessage">
            <div *ngIf="isAssociation !== 'true'; else titleAssociation">
                <p translate class="sm-md-text text-align-center title">upload-file.title</p>
            </div>

            <ng-template #titleAssociation>
                <p translate class="sm-md-text text-align-center title">upload-file.titleAssociation</p>
            </ng-template>
            <div class="separator">
                <hr />
            </div>
            <p translate class="sm-text text-align-center sub-title">upload-file.sub-title</p>
            <div *ngIf="showIframe && netheosUrl" class="iframe-netheos">
                <iframe id="signbook" [src]="netheosUrl | sanitizer" scrolling="yes" frameBorder="no" width="100%" height="530px"></iframe>
            </div>
        </div>
        <div class="waiting" *ngIf="showWaitingMessage">
            <app-pending-dossier></app-pending-dossier>
        </div>
    </div>
</div>
