import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { User } from 'src/app/models/user';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faInfoCircle, faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-rights-holders-manager',
    templateUrl: './rights-holders-manager.component.html',
    styleUrls: ['./rights-holders-manager.component.scss']
})
export class RightsHoldersManagerComponent implements OnInit {
    @Input()
    rightsHolders: User[] = [];
    @Input()
    waitingRightsHolders: User[] = [];
    @Output()
    validate = new EventEmitter<object>();
    @Output()
    delete = new EventEmitter<object>();

    faInfoCircle = faInfoCircle;
    faUserCircle = faUserCircle;
    faTimes = faTimes;

    selectedWaitingRightHolder: User = null;

    constructor(private modal: ModalService) {}

    ngOnInit() {}

    select(event, waitingRightHolder) {
        const isAlreadySelected = waitingRightHolder.selected;
        this.unselectRightHolders();
        waitingRightHolder.selected = !isAlreadySelected;
        isAlreadySelected ? (this.selectedWaitingRightHolder = null) : (this.selectedWaitingRightHolder = waitingRightHolder);
        event.stopPropagation();
    }

    unselectRightHolders() {
        this.waitingRightsHolders.forEach(element => {
            element.selected = false;
        });
    }

    close(event, user) {
        this.delete.emit({ user });
        event.stopPropagation();
    }

    allowUser(event) {
        if (this.selectedWaitingRightHolder) {
            const validatedUser = this.selectedWaitingRightHolder;
            this.validate.emit({ validatedUser });
        }
    }
}
