import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-representative-list',
    templateUrl: './representative-list.component.html',
    styleUrls: ['./representative-list.component.scss']
})
export class RepresentativeListComponent implements OnInit {
    @Input()
    representatives: User[] = [];
    @Input()
    currentUser: User = new User();

    faUserCircle = faUserCircle;

    constructor() {}

    ngOnInit() {}
}
