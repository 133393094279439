<!-- Partie validation en cours -->
<div *ngIf="!identityFailed && !isCompanyApiUnvailable">
    <!-- Image temporaire en attendant la vraie image -->
    <img src="assets/img/loader.png" class="centered-element page-image" alt="{{ 'alt.verifyIdentityPending' | translate }}" />
    <div class="row pt-4">
        <h2 *ngIf="!identityOK" class="text-align-center md-text col" translate>
            validation.inProgress
        </h2>
        <h2 *ngIf="identityOK" class="text-align-center md-text col" translate>
            validation.successfull
        </h2>
    </div>
    <div class="separator">
        <hr />
    </div>
    <div *ngIf="!identityOK" class="text-align-center row">
        <div class="col">
            <span translate class="sm-text">validation.bePatient</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ul class="check-list">
                <li>
                    <img *ngIf="!identityOK" src="/assets/img/loading.svg" class="loader rotate" alt="Chargement" />
                    <img *ngIf="identityOK" src="/assets/img/check-icon.svg" alt="{{ 'alt.identityOK' | translate }}" />
                    <span translate>validation.checkIdentity</span>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Partie has error -->
<div *ngIf="identityFailed && !isCompanyApiUnvailable" class="d-flex flex-column align-items-center">
    <img src="assets/img/erreur.png" class="centered-element page-image" alt="{{ 'alt.error' | translate }}" />
    <div class="text-align-center">
        <div class="row pt-4">
            <h3 class="word-break-break-word col" translate>validation.hasMessages</h3>
        </div>
        <div class="separator">
            <hr />
        </div>
        <div class="row">
            <div class="col">
                <ul class="message-list">
                    <li *ngFor="let message of messages" [innerHTML]="message"></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ul class="check-list">
                    <li>
                        <img *ngIf="identityOK" src="/assets/img/check-icon.svg" alt="{{ 'alt.identityOK' | translate }}" />
                        <img *ngIf="!identityOK" src="/assets/img/alert-icon.svg" alt="{{ 'alt.identityKO' | translate }}" />
                        <span translate>validation.checkIdentity</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="showReCaptcha">
            <div class="col pl-4">
                <ngx-recaptcha2
                    #captchaElem1
                    name="captcha1"
                    [siteKey]="siteKey"
                    [size]="'normal'"
                    (expire)="onReCaptchaExpiration(captchaElem1)"
                    (ready)="onLoadCaptcha(captchaElem1)"
                    (success)="onReCaptcha(true)"
                    [(ngModel)]="captcha1"
                    [ngModelOptions]="{ standalone: true }"
                >
                </ngx-recaptcha2>
            </div>
        </div>
        <div class="row pt-4" *ngIf="!hasError">
            <div class="col">
                <button
                    class="btn "
                    (click)="completeFolder()"
                    [class.disabled]="!isCaptcha1Validated"
                    [disabled]="!isCaptcha1Validated"
                    translate
                >
                    validation.completeDossier
                </button>
            </div>
        </div>
        <div class="row pt-4" *ngIf="hasError">
            <div class="col">
                <button class="btn " (click)="hide()" translate>Close</button>
            </div>
        </div>
    </div>
</div>

<app-api-unvailable
    *ngIf="isCompanyApiUnvailable"
    [subtitle]="'apiCompanyUnavailable' | translate"
    [firstMessage]="'tryAgainLater' | translate"
    [actionButtonText]="'goBackToClientApp' | translate"
    (action)="goBackToClientApp()"
>
</app-api-unvailable>
