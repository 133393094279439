import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalService, Modal } from '../../../services/modal/modal.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
    modalOpen = false;
    text: SafeHtml;

    constructor(private modalService: ModalService, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.modalService.register(Modal.Alert, this);
    }

    init(text) {
        this.text = this.sanitizer.bypassSecurityTrustHtml(text) || 'Oops!';
    }

    show() {
        this.modalOpen = true;
    }

    hide() {
        this.modalOpen = false;
    }
}
