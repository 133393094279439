<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <h2 translate>inviteTitle</h2>
        <form #form="ngForm" (ngSubmit)="send(form.value)">
            <div class="form-group">
                <label for="recipients" translate>inviteRecipient</label>
                <input
                    type="text"
                    class="form-control recipients"
                    id="recipients"
                    name="recipients"
                    [(ngModel)]="recipients"
                    (keyup)="isValidEmailList($event)"
                />
                <button type="button" class="cancel-btn" (click)="cancel()">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
            <div class="form-group">
                <textarea id="message" name="message" class="message-body" [(ngModel)]="message" rows="8"></textarea>
            </div>
            <button type="submit" class="btn" [ngClass]="{ 'b-selected': !error }" [disabled]="error">
                <span translate>send</span>
            </button>
        </form>
    </div>
</app-modal>
