import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Entity, EntityTypes } from 'src/app/models/entity';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
    selector: 'app-form-siret',
    templateUrl: './form-siret.component.html',
    styleUrls: ['./form-siret.component.scss']
})
export class FormSiretComponent implements OnInit, AfterViewInit {
    @Input()
    siret = '';

    @Input()
    submitAction: string;

    @Input()
    autofocus = false;

    @Input()
    errorState = false;

    @Input()
    errorMessage = '';

    @Output()
    submit = new EventEmitter<any>();

    projectEntities: Array<Entity>;
    connectProject: string;
    projectID: string;
    transformProject: string;
    pattern = new RegExp('[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{5}');

    @ViewChild('siretInput', { static: true })
    siretInput: ElementRef;

    constructor(private changeDetector: ChangeDetectorRef, private auth: AuthService, private localStorageService: LocalStorageService) {}

    ngOnInit() {
        this.transformProject = this.localStorageService.getTransformProject();
        this.connectProject = this.localStorageService.getProjectId();
        if (!this.transformProject || this.transformProject !== '') {
            const entities = this.auth.getEntities();
            this.projectEntities = entities.filter(entity => entity.type === EntityTypes.Project);
        }

        this.projectID = this.connectProject;
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.siretInput.nativeElement.focus();
        }
    }

    formatSiret(value) {
        this.changeDetector.detectChanges();
        const lastSpace = value[value.length - 1] === ' ' ? ' ' : '';
        let newVal = value.replace(/\D/g, '');

        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1') + lastSpace;
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2') + lastSpace;
        } else if (newVal.length <= 9) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1 $2 $3' + lastSpace);
        } else {
            newVal = newVal.substring(0, 14);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,5})/, '$1 $2 $3 $4');
        }
        this.siret = newVal;
    }

    onItemChange(value) {
        this.projectID = value;
    }

    onSubmit() {
        if (this.siret && this.siret != '' && this.pattern.test(this.siret)) {
            this.submit.emit({
                siret: this.siret,
                project: this.projectID
            });
        }
    }
}
