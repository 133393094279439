<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="row pt-3 pl-2 pr-2">
        <div class="col">
            <p class="content">
                <strong [innerHTML]="text"></strong>
            </p>
        </div>
    </div>

    <div class="row pb-4">
        <div *ngIf="bVerifyIdentity" class="col">
            <a class="check-affiliation-btn" href="#" (click)="openNetheosModal($event)" translate>verifyIdentity</a>
        </div>
        <div *ngIf="bCheckAffiliation" class="col">
            <app-check-company-affiliation
                (checkAffiliation)="checkCompanyAffiliation($event)"
                [entity]="entity"
                [bText]="true"
            ></app-check-company-affiliation>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <button (click)="hide()" class="btn" [attr.aria-label]="'Close' | translate" #focus translate>Close</button>
        </div>
    </div>
</app-modal>
