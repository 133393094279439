import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-form-project',
    templateUrl: './form-project.component.html',
    styleUrls: ['./form-project.component.scss']
})
export class FormProjectComponent implements OnInit, AfterViewInit {
    @Input()
    project = '';

    @Input()
    submitAction: string;

    @Input()
    autofocus = false;

    @Input()
    errorState = false;

    @Input()
    errorMessage = '';

    @Output()
    submit = new EventEmitter<string>();

    @ViewChild('projectInput', { static: true })
    projectInput: ElementRef;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.projectInput.nativeElement.focus();
        }
    }

    onSubmit() {
        if (this.project && this.project != '') {
            this.submit.emit(this.project.trim());
        }
    }

    formatProjet(value) {
        this.changeDetector.detectChanges();
        if (this.project.trim().length === 0) {
            this.project = this.project.trim();
        }
    }
}
