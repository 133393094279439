import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrderModule } from 'ngx-order-pipe';
import { AppAuthModule } from './app-auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanySimpleCardComponent } from './components/company-simple-card/company-simple-card.component';
import { FormSiretComponent } from './components/forms/form-siret/form-siret.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AlertComponent } from './components/modal/alert/alert.component';
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { LoaderComponent } from './components/modal/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { PendingDossierComponent } from './components/pending-dossier/pending-dossier.component';
import { SpinnerDotsComponent } from './components/spinner-dots/spinner-dots.component';
import { AuthGuard } from './guards/auth.guard';
import { ConnectGuard } from './guards/connect.guard';
import { GestionGuard } from './guards/gestion.guard';
import { EntityManagerComponent } from './pages/entity-manager/entity-manager.component';
import { InviteModalComponent } from './pages/entity-manager/invite-modal/invite-modal.component';
import { RepresentativeListComponent } from './pages/entity-manager/representative-list/representative-list.component';
import { RightsHoldersManagerComponent } from './pages/entity-manager/rights-holders-manager/rights-holders-manager.component';
import { EntrepriseComponent } from './pages/entreprise/entreprise.component';
import { EntityAddComponent } from './pages/home/entity-add/entity-add.component';
import { EntityListComponent } from './pages/home/entity-list/entity-list.component';
import { HomeComponent } from './pages/home/home.component';
import { UploadFileComponent } from './pages/upload-file/upload-file.component';
import { ValidationComponent } from './pages/validation/validation.component';
import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { AuthService } from './services/auth/auth.service';
import { EncryptorService } from './services/encryptor.service';
import { ApiService } from './services/gdi-api/api.service';
import { CompanyService } from './services/gdi-api/company.service';
import { GroupService } from './services/gdi-api/group.service';
import { NetheosService } from './services/gdi-api/netheos.service';
import { LocalStorageService } from './services/local-storage.service';
import { ModalService } from './services/modal/modal.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ProjectComponent } from './pages/project/project.component';
import { FormProjectComponent } from './components/forms/form-project/form-project.component';
import { ProjectService } from './services/gdi-api/project.service';
import { ProjectSimpleCardComponent } from './components/project-simple-card/project-simple-card.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { IsInternalPageService } from './services/is-internal-page.service';
import { ApiUnvailableComponent } from './components/api-unvailable/api-unvailable.component';
import { AlertActionComponent } from './components/modal/alert-action/alert-action.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeycloakAngularModule } from 'keycloak-angular';
import { faBuilding, faPlusSquare, faTrashAlt, faUserCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDoubleLeft,
    faCaretRight,
    faCheck,
    faPlus,
    faSignOutAlt,
    faSpinner,
    faCircleNotch,
    faTimes,
    faToggleOn,
    faUserPlus,
    faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { BrowserDetectionComponent } from './components/browser-detection/browser-detection.component';
import { CheckCompanyAffiliationComponent } from './components/check-company-affiliation/check-company-affiliation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { CguModalComponent } from './components/modal/cgu-modal/cgu-modal.component';
import { RgpdModalComponent } from './components/modal/rgpd-modal/rgpd-modal.component';
import { PlanDuSiteModalComponent } from './components/modal/plandusite-modal/plandusite-modal.component';
import { AccessibiliteModalComponent } from './components/modal/accessibilite-modal/accessibilite-modal.component';
import { ProfilCompletionModuleComponent } from './components/profil-completion-module/profil-completion-module.component';
import { ProfilCompletionGaugeComponent } from './components/profil-completion-gauge/profil-completion-gauge.component';
import { GaugeModule } from 'angular-gauge';
import { ProfilCompletionActionsComponent } from './components/profil-completion-actions/profil-completion-actions.component';
import { NetheosFormComponent } from './components/netheos-form/netheos-form.component';
import { NetheosFormModalComponent } from './components/modal/netheos-form-modal/netheos-form-modal.component';
import { NetheosIframeComponent } from './components/netheos-iframe/netheos-iframe.component';
import { ProfilCompletionService } from './services/profil-completion-service.service';
import { AccessService } from './services/gdi-api/access.service';
import { AccessErrorComponent } from './components/modal/access-error/access-error.component';
import { NetheosAssociationsModalComponent } from './components/modal/netheos-associations-modal/netheos-associations-modal.component';
import { SortEntityPipe } from './pipes/sort-entity.pipe';
import { EntityManagerAccessComponent } from './components/entity-manager-access/entity-manager-access.component';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { CookiesModalComponent } from './components/modal/cookies-modal/cookies-modal.component';
import { TrapFocusDirective } from './directives/trap-focus.directive';
import { AllowedRedirectUrisService } from './services/gdi-api/allowed-redirect-uris.service';
import { ClientButtonListComponent } from './pages/home/client-button-list/client-button-list.component';
import { CheckingAccessService } from './services/checking-access/checking-access.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

@NgModule({
    declarations: [
        AccessErrorComponent,
        AlertActionComponent,
        AlertComponent,
        AppComponent,
        ApiUnvailableComponent,
        BrowserDetectionComponent,
        CheckCompanyAffiliationComponent,
        CompanySimpleCardComponent,
        ConfirmComponent,
        EntityAddComponent,
        EntityListComponent,
        EntityManagerAccessComponent,
        EntityManagerComponent,
        EntrepriseComponent,
        FormSiretComponent,
        FormProjectComponent,
        HeaderComponent,
        HomeComponent,
        InviteModalComponent,
        LoaderComponent,
        LoadingScreenComponent,
        MainMenuComponent,
        ModalComponent,
        PendingDossierComponent,
        ProjectComponent,
        ProjectSimpleCardComponent,
        RepresentativeListComponent,
        RightsHoldersManagerComponent,
        SpinnerDotsComponent,
        SanitizerPipe,
        SortEntityPipe,
        TruncatePipe,
        UploadFileComponent,
        ValidationComponent,
        FooterComponent,
        CguModalComponent,
        CookiesModalComponent,
        PlanDuSiteModalComponent,
        RgpdModalComponent,
        AccessibiliteModalComponent,
        ProfilCompletionModuleComponent,
        ProfilCompletionGaugeComponent,
        ProfilCompletionActionsComponent,
        NetheosAssociationsModalComponent,
        NetheosFormComponent,
        NetheosFormModalComponent,
        NetheosIframeComponent,
        TrapFocusDirective,
        ClientButtonListComponent
    ],
    imports: [
        BrowserModule,
        AppAuthModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        OrderModule,
        NgxCaptchaModule,
        FontAwesomeModule,
        NgxCaptchaModule,
        KeycloakAngularModule,
        GaugeModule.forRoot(),
        NgbModule
    ],
    providers: [
        SortEntityPipe,
        ApiService,
        AccessService,
        AllowedRedirectUrisService,
        AuthGuard,
        AuthService,
        CompanyService,
        ConnectGuard,
        EncryptorService,
        GestionGuard,
        GroupService,
        LocalStorageService,
        ModalService,
        NetheosService,
        ProjectService,
        ProfilCompletionService,
        IsInternalPageService,
        CheckingAccessService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faUsersCog);
        library.addIcons(faPlus);
        library.addIcons(faPlusSquare);
        library.addIcons(faTimes);
        library.addIcons(faToggleOn);
        library.addIcons(faAngleDoubleLeft);
        library.addIcons(faSignOutAlt);
        library.addIcons(faCircleNotch);
        library.addIcons(faSpinner);
        library.addIcons(faBuilding);
        library.addIcons(faUserPlus);
        library.addIcons(faUserCircle);
        library.addIcons(faCaretRight);
        library.addIcons(faTrashAlt);
        library.addIcons(faCheck);
        library.addIcons(faCheckCircle);
        library.addIcons(faUserEdit);
    }
}
