import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ModalCookies = 'cookies';

@Component({
    selector: 'app-cookies-modal',
    templateUrl: './cookies-modal.component.html',
    styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent implements OnInit {
    modalOpen = false;
    faTimes = faTimes;

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(ModalCookies, this);
    }

    // Mandatory para Modal Service
    show() {
        this.modalOpen = true;
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
    }

    onCancel() {}

    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }

    showTarteaucitronPopIn() {
        return window.location + '#tarteaucitron';
    }
}
