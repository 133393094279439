import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class IsInternalPageService {
    isInternalPageSubject = new BehaviorSubject<boolean>(false);
    currentValue = this.isInternalPageSubject.asObservable();

    constructor() {}

    changeValue(value: boolean) {
        this.isInternalPageSubject.next(value);
    }
}
