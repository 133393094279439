import { Component, OnChanges, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges, OnInit {
    @HostListener('document:keydown.escape', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
        this.close.emit();
    }

    @Input() isOpen: boolean;
    @Output() close = new EventEmitter();

    _open: boolean;

    constructor(private modal: ModalService) {}
    ngOnInit(): void {
        this.modal.showLoader.subscribe(show => {
            this._open = show;
        });
    }
    ngOnChanges() {
        this._open = this.isOpen ? true : false;
    }

    preventClose(event) {
        event.stopPropagation();
    }
}
