import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environmentLoader } from '../../../environments/environmentLoader';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ApiService {
    apiHost = '';
    netheosUrl = '';

    defaultHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient, private auth: AuthService) {
        environmentLoader.then(env => {
            this.apiHost = env.settings.api.host;
            this.netheosUrl = env.settings.netheos;
        });
    }

    getHeaders() {
        return this.auth
            .isLoggedIn()
            .then(loggedIn => {
                if (loggedIn) {
                    return this.auth.addTokenToHeader(this.defaultHeaders).toPromise();
                } else {
                    return Promise.resolve(this.defaultHeaders);
                }
            })
            .catch(() => Promise.resolve(this.defaultHeaders));
    }

    get(endpoint: string) {
        return this.getHeaders().then(headers => {
            return this.httpResponseWrapper(this.http.get(`${this.apiHost}/${endpoint}`, { headers }));
        });
    }

    post(endpoint: string, params: object = {}) {
        return this.getHeaders().then(headers =>
            this.httpResponseWrapper(this.http.post(`${this.apiHost}/${endpoint}`, params, { headers }))
        );
    }

    put(endpoint: string, params: object = {}) {
        return this.getHeaders().then(headers =>
            this.httpResponseWrapper(this.http.put(`${this.apiHost}/${endpoint}`, params, { headers }))
        );
    }

    delete(endpoint: string) {
        return this.getHeaders().then(headers => this.httpResponseWrapper(this.http.delete(`${this.apiHost}/${endpoint}`, { headers })));
    }

    private httpResponseWrapper(httpResponse: Observable<any>) {
        return httpResponse.toPromise();
    }
}
