<div class="gdi-home-container custom-row">
    <div class="user-informations profilCard custom-netheos custom-col-3" *ngIf="useNetheos">
        <div class="lg-text text-align-center" *ngIf="currentUser">
            <div class="firstname">
                {{ currentUser.firstname }}
            </div>
            <div class="lastname">
                {{ currentUser.lastname }}
            </div>
        </div>
        <div class="separator">
            <hr />
        </div>
        <div class="row">
            <div class="pt-2 col">
                <profil-completion-module></profil-completion-module>
            </div>
        </div>
    </div>

    <div class="user-informations custom-col-3" *ngIf="!useNetheos">
        <div class="profilCard">
            <div class="lg-text text-align-center" *ngIf="currentUser">
                <div class="firstname">
                    {{ currentUser.firstname }}
                </div>
                <div class="lastname">
                    {{ currentUser.lastname }}
                </div>
            </div>
            <div class="row">
                <div class="pt-2 col text-align-center">
                    <a href="{{ goToEditProfile() }}">
                            <span class="text-left" translate>
                                editProfile <fa-icon [icon]="faPen" size="1x" [class]="'icon-color-grey'" transform="right-2"></fa-icon>
                            </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div id="services-container" class="custom-col-9">
        <app-client-button-list
            (selectEvent)="selectClient($event)"
            [entities]="entities" 
            [clients]="clients" 
            [currentUser]="currentUser">
        </app-client-button-list>
    </div>
    <div class="companies-and-projects-list custom-col-9">
        <section>
            <div class="d-flex justify-content-between align-items-baseline pt-4 pb-2">
                <h2 class="uppercase md-text" translate>myCompanies</h2>
                <button
                    class="uppercase add-link pointer link-custom-focus"
                    (click)="goToCompanySiretSearchPage()"
                    placement="bottom"
                    ngbTooltip="{{ 'AddCompany' | translate }}"
                    [attr.aria-label]=" 'AddCompany' | translate "
                >
                    <span translate><fa-icon [icon]="faPlus"></fa-icon> Add</span>
                </button>
            </div>
            <hr class="horizontal-separator pb-3" />
            <app-entity-list *ngIf="companyEntities.length > 0"
                [entities]="companyEntities"
                [currentUser]="currentUser"
                (select)="clickEntity($event)"
                (remove)="removeEntity($event)"
                (checkAffiliation)="checkCompanyAffiliation($event)"
            >
            </app-entity-list>
        </section>
        <section>
            <div class="d-flex justify-content-between pt-4 pb-2">
                <h2 class="uppercase md-text" translate>myProjects</h2>
                <button
                    class="uppercase add-link pointer link-custom-focus"
                    (click)="goToProjectSearchPage()"
                    placement="bottom"
                    ngbTooltip="{{ 'AddProject' | translate }}"
                    [attr.aria-label]=" 'AddProject' | translate "
                >
                    <span translate><fa-icon [icon]="faPlus"></fa-icon> Add</span>
                </button>
            </div>
            <hr class="horizontal-separator pb-3" />
            <app-entity-list *ngIf="projectEntities.length > 0"
                [entities]="projectEntities"
                [currentUser]="currentUser"
                (select)="clickEntity($event)"
                (remove)="removeEntity($event)"
            >
            </app-entity-list>
        </section>
    </div>
    <div>
        <span translate>projectConcerned</span>
    </div>
</div>
<app-access-error
    (checkAffiliation)="checkCompanyAffiliation($event)"
    [bCheckAffiliation]="bCheckAffiliation"
    [bVerifyIdentity]="bVerifyIdentity"
    [entity]="currentEntity"
></app-access-error>
<!-- <app-loader></app-loader> -->
