import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalService, Modal } from '../../../services/modal/modal.service';

@Component({
    selector: 'app-confirm',
    styleUrls: ['./confirm.component.scss'],
    templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
    @ViewChild('confirm') confirmButtonElement: ElementRef;
    modalOpen = false;
    text: string;

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(Modal.Confirm, this);
    }

    init(params) {
        if (params) {
            this.text = params.text || 'Oops!';
            if (params.onSuccess) {
                this.onSuccess = params.onSuccess;
            }
            if (params.onCancel) {
                this.onCancel = params.onCancel;
            }
        }
    }

    onSuccess() {}
    ok() {
        this.onSuccess();
        this.modalOpen = false;
    }

    onCancel() {}
    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }

    show() {
        this.modalOpen = true;
        setTimeout(() => {
            // this will make the execution after the above boolean has changed
            this.confirmButtonElement.nativeElement.focus();
        }, 0);
    }

    hide() {
        this.modalOpen = false;
    }
}
