<div class="link-action">
    <div class="row pl-3">
        <div class="col-auto text-left" translate>
            <fa-icon [icon]="faCheckCircle" size="lg" [class]="'icon-color-green'"></fa-icon> identityData
        </div>
    </div>
</div>
<div class="link-action">
    <div class="row pl-3">
        <a *ngIf="!isVerified" (click)="openNetheosModal($event)" aria-label="Vérifier mon identité" href="javascript:void(0)"  >
            <div class="col-auto text-left" translate>
                <fa-icon [icon]="faCheckCircle" size="lg" [class]="'icon-color-grey'"></fa-icon> verifyIdentity
            </div>
        </a>
        <div *ngIf="isVerified" class="col-auto text-left" translate>
            <fa-icon [icon]="faCheckCircle" size="lg" [class]="'icon-color-green'"></fa-icon> identityVerified
        </div>
    </div>
</div>
<div class="link-action">
    <a href="{{ goToEditProfile() }}" aria-label="Modifier mon profil">
        <div class="row pl-3">
            <div class="col-auto text-left" translate>
                <fa-icon [icon]="faUserEdit" size="1x" [class]="'icon-color-grey'" transform="right-2"></fa-icon> editProfile
            </div>
        </div>
    </a>
</div>
