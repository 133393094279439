import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AllowedRedirectUrisService extends AbstractService {
    private readonly _allowedRedirectUris$: Observable<string[]>;
    private _allowedRedirectUris$$ = new BehaviorSubject<string[]>([]);

    constructor(injector: Injector) {
        super(injector);
        this.endpoint = 'gdi-entreprise/allowed-redirect-uris';
        this._allowedRedirectUris$ = this._allowedRedirectUris$$.asObservable();
        this.getAllowedRedirectUris().then((allowedRedirectUris: string[]) => {
            this.allowedRedirectUris = allowedRedirectUris;
        });
    }

    get allowedRedirectUris(): string[] {
        return this._allowedRedirectUris$$.getValue();
    }

    get allowedRedirectUris$(): Observable<string[]> {
        return this._allowedRedirectUris$;
    }

    set allowedRedirectUris(allowedRedirectUris: string[]) {
        this._allowedRedirectUris$$.next(allowedRedirectUris);
    }

    /**
     * @return string array containing all allowed redirect URIs
     */
    getAllowedRedirectUris(): Promise<string[]> {
        return this.api.get(this.endpoint);
    }

    isValidRedirectURI(redirectUri: string): boolean {
        return redirectUri ? this.allowedRedirectUris.some(allowedRedirectUri => redirectUri.includes(allowedRedirectUri)) : false;
    }
}
