<div *ngIf="!showWaitingMessage">
    <div class="row" *ngIf="isAssociation !== 'true'; else titleAssociation">
        <div class="col">
            <p translate class="sm-md-text text-align-center title">upload-file.title</p>
        </div>
    </div>

    <ng-template #titleAssociation>
        <div class="col">
            <p translate class="sm-md-text text-align-center title">upload-file.titleAssociation</p>
        </div>
    </ng-template>
    <div class="separator">
        <hr />
    </div>
</div>
<div class="row" *ngIf="!showWaitingMessage">
    <div class="col">
        <p translate class="sm-text text-align-center sub-title">upload-file.sub-title</p>
    </div>
</div>

<div *ngIf="showIframe && netheosUrl" class="iframe-netheos row">
    <div class="col fill-available">
        <iframe id="signbook" [src]="netheosUrl | sanitizer" scrolling="yes" frameBorder="no" width="100%" height="100%"> </iframe>
    </div>
</div>
<div class="waiting row" *ngIf="showWaitingMessage">
    <div class="col">
        <app-pending-dossier></app-pending-dossier>
    </div>
</div>
