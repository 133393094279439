import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { joinGroupMessages_Association, joinGroupMessages_IdentityFail, joinGroupMessages_LinkFail, roleVerified } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GroupService } from 'src/app/services/gdi-api/group.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModalService } from 'src/app/services/modal/modal.service';

import { environmentLoader } from '../../../environments/environmentLoader';
import { ProjectService } from '../../services/gdi-api/project.service';
import { IsInternalPageService } from '../../services/is-internal-page.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, OnDestroy {
    redirectUri: string;
    accountUri: string;
    hubUri: string;
    openDossierWithLabel: string;
    openDossierWithToken: string;
    showContinue = false;
    hasMessages = false;
    identityOK = false;
    linkOK = false;
    messages: string[] = [];
    siret: any;
    project: any;
    projectId: string;
    accessProject: string;
    captcha1: any;
    captcha2: any;
    isCaptcha1Validated = false;
    isCaptcha2Validated = false;
    siteKey: string;
    isCompanyApiUnvailable = false;

    subscritpions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private api: GroupService,
        private projectApi: ProjectService,
        private auth: AuthService,
        private translate: TranslateService,
        private modal: ModalService,
        public localStorageService: LocalStorageService,
        private isInternalPageService: IsInternalPageService
    ) {
        this.subscritpions.push(
            this.route.queryParams.subscribe(params => {
                this.redirectUri = params['redirect_uri'] ? params['redirect_uri'] : this.localStorageService.getRedirectUri();
            })
        );
        environmentLoader.then(env => {
            this.accountUri = `${env.settings.auth.keycloak.url}/realms/${env.settings.auth.keycloak.realm}/account?referrer=${document.location}`;
            this.hubUri = `${env.settings.hub.url}`;
        });
    }

    ngOnInit() {
        this.siret = this.localStorageService.getSiret();
        this.project = this.localStorageService.getProjectName();
        this.projectId = this.localStorageService.getProjectId();
        this.accessProject = this.localStorageService.getAccessProject();
        this.localStorageService.deleteIsAssociation();

        environmentLoader.then(env => {
            this.siteKey = env.settings.recaptcha.siteKey;
        });
        this.identityOK = this.auth.getUserRoles().indexOf(roleVerified) > -1;
        this.auth.getUserDefaultEntity().then((defaultEntity: string) => {
            if (defaultEntity && defaultEntity.includes('-')) {
                this.projectId = defaultEntity;
            } else {
                this.siret = defaultEntity;
            }
        });

        if (this.accessProject === 'true') {
            this.api
                .joinGroupAccessProject(this.siret)
                .then(response => this.computeJoinResponse(response))
                .catch(error => {
                    if (error.status === 404 && error.error.message === 'Le service public API Entreprise ne répond pas.') {
                        this.isCompanyApiUnvailable = true;
                    } else {
                        if (error.error) {
                            this.raiseError(error.error);
                        } else {
                            this.raiseError(error);
                        }
                    }
                });
        } else {
            this.api
                .joinGroupAndCheckAffiliation(
                    this.projectId && !this.siret ? this.projectId : this.siret,
                    this.projectId && this.siret ? { 'keycloak-group-id': this.projectId } : null
                )
                .then(response => this.computeJoinResponse(response))
                .catch(error => {
                    if (error.status === 404 && error.error.message === 'Le service public API Entreprise ne répond pas.') {
                        this.isCompanyApiUnvailable = true;
                    } else {
                        if (error.error) {
                            this.raiseError(error.error);
                        } else {
                            this.raiseError(error);
                        }
                    }
                });
        }
        this.isInternalPageService.changeValue(true);
    }

    ngOnDestroy() {
        this.subscritpions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    computeJoinResponse(response) {
        if (response.messages && Object.keys(response.messages).length) {
            this.showContinue = false;
            this.hasMessages = true;

            if (response.messages._$WorkflowLabel) {
                this.openDossierWithLabel = response.messages._$WorkflowLabel;
            }

            if (response.messages._$DossierToken) {
                this.openDossierWithToken = response.messages._$DossierToken;
            }

            let linkFailed = false;
            let identityFailed = false;
            this.messages = Object.keys(response.messages)
                .map((key: string) => {
                    // Check list status
                    if (joinGroupMessages_LinkFail.indexOf(key) > -1) {
                        linkFailed = true;
                    }
                    if (joinGroupMessages_IdentityFail.indexOf(key) > -1) {
                        identityFailed = true;
                    }
                    if (joinGroupMessages_Association.indexOf(key) > -1) {
                        this.localStorageService.setIsAssociation('true');
                    }

                    // Return message
                    const message = response.messages[key];
                    return key.indexOf('_$') !== 0 ? message.replace('. ', '.<br/>') : null;
                })
                .filter(v => v !== null);

            if (identityFailed) {
                // WHen identity failed, this means link was OK
                this.identityOK = false;
                this.linkOK = true;
            } else {
                if (linkFailed) {
                    this.linkOK = false;
                }
            }
        } else {
            this.showContinue = true;
            this.hasMessages = false;
        }
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    refreshUser() {
        return this.auth.refreshUser();
    }

    raiseError(error) {
        this.refreshUser();
        console.error('ERROR', error);
        const msg = error.message || this.t('errorCommon');
        this.modal.alert(msg);
    }

    goBackToClientApp() {
        this.router.navigate(['/'], {
            queryParamsHandling: 'preserve'
        });
    }

    onCaptchaExpiration(captcha: ReCaptcha2Component) {
        captcha.resetCaptcha();
    }
}
