export class Attribute {
    private _name: string;
    private _values: string[];

    constructor(name?: string, values?: string[]) {
        name ? (this._name = name) : (this._name = null);
        values ? (this._values = values) : (this._values = []);
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get values(): string[] {
        return this._values;
    }

    set values(value: string[]) {
        this._values = value;
    }

    add(value: string) {
        this._values.push(value);
    }

    remove(value: string) {
        this._values.splice(this._values.indexOf(value), 1);
    }
}
