<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>cgu-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <p translate>cgu-modal.intro.part-one</p>
                    <p translate>cgu-modal.intro.part-two</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-1.title</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-1.i-am-concerned-if.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-1.i-am-concerned-if.part-one</li>
                        <li translate>cgu-modal.sub-section-1.i-am-concerned-if.part-two</li>
                        <li translate>cgu-modal.sub-section-1.i-am-concerned-if.part-three</li>
                        <li translate>cgu-modal.sub-section-1.i-am-concerned-if.part-four</li>
                    </ul>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-1.i-am-not-concerned-if.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-1.i-am-not-concerned-if.part-one</li>
                        <li translate>cgu-modal.sub-section-1.i-am-not-concerned-if.part-two</li>
                    </ul>
                    <p translate>cgu-modal.sub-section-1.asterisk</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-2.title</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-2.i-am-concerned-if.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-2.i-am-concerned-if.part-one</li>
                        <li translate>cgu-modal.sub-section-2.i-am-concerned-if.part-two</li>
                        <li translate>cgu-modal.sub-section-2.i-am-concerned-if.part-three</li>
                    </ul>
                    <p translate>cgu-modal.sub-section-2.i-am-concerned-if.asterisk</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-2.i-am-not-concerned-if.title</p>
                    <ul>
                        <li translate>cgu-modal.sub-section-2.i-am-not-concerned-if.part-one</li>
                        <li translate>cgu-modal.sub-section-2.i-am-not-concerned-if.part-two</li>
                    </ul>
                    <p translate>cgu-modal.sub-section-2.outro.part-one</p>
                    <p translate>cgu-modal.sub-section-2.outro.part-two</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-3.title</p>
                    <p translate>cgu-modal.sub-section-3.part-one</p>
                    <p translate>cgu-modal.sub-section-3.part-two</p>
                    <p translate>cgu-modal.sub-section-3.part-three</p>
                    <p class="font-weight-bold" translate>cgu-modal.sub-section-4.title</p>
                    <p translate>cgu-modal.sub-section-4.part-one</p>
                    <p translate>cgu-modal.sub-section-4.part-two</p>
                    <p translate>cgu-modal.sub-section-4.part-three</p>
                    <p translate>cgu-modal.sub-section-4.part-four</p>
                    <p>
                        <span translate>cgu-modal.sub-section-4.part-five</span>
                        <a href="https://hubentreprendre.laregion.fr/page/donnees-personnelles#tarteaucitron" target="_blank">
                            <span translate>cgu-modal.sub-section-4.part-five-link</span>
                        </a>
                    </p>
                    <p translate>cgu-modal.sub-section-4.part-six</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
