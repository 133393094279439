<button
    *ngIf="!bText"
    (click)="checkCompanyAffiliation($event, entity)"
    placement="bottom"
    ngbTooltip="{{ 'CheckAffiliation' | translate }}"
    class="check-affiliation-btn link-custom-focus"
    [attr.aria-label]="'alt.checkAffiliationWithCompany' | translate"
>
    <span><fa-icon [icon]="faLink" size="1x"></fa-icon></span>
</button>

<a *ngIf="bText" href="#" (click)="checkCompanyAffiliation($event, entity)" translate>CheckAffiliation</a>
