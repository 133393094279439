import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { ConnectGuard } from './guards/connect.guard';
import { GestionGuard } from './guards/gestion.guard';
import { EntityManagerComponent } from './pages/entity-manager/entity-manager.component';
import { EntrepriseComponent } from './pages/entreprise/entreprise.component';
import { HomeComponent } from './pages/home/home.component';
import { UploadFileComponent } from './pages/upload-file/upload-file.component';
import { ValidationComponent } from './pages/validation/validation.component';
import { ProjectComponent } from './pages/project/project.component';

/* Guard  */
/* Component */
const routes: Routes = [
    // New workflow routes
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard, ConnectGuard]
    },
    {
        path: 'entreprise',
        component: EntrepriseComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'validation',
        component: ValidationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'uploadFile/:id',
        component: UploadFileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'uploadFile/type/:workflowLabel',
        component: UploadFileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'gestion/:id',
        component: EntityManagerComponent,
        data: {},
        canActivate: [AuthGuard, GestionGuard]
    },
    {
        path: 'connect',
        component: HomeComponent,
        canActivate: [AuthGuard, ConnectGuard]
    },
    {
        path: 'projet',
        component: ProjectComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [
        {
            provide: 'isDevEnvironment',
            useValue: () => environment.production === false
        }
    ]
})
export class AppRoutingModule {}
