<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="occ-modal-title" translate>sitemap-modal.title</h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <div class="occ-modal-body">
                    <ul>
                        <li class="">
                            <a
                                href="/"
                                translate>
                                sitemap-modal.doHome
                            </a>
                        </li>
                        <li class="">
                            <a
                                href="javascript:void(0)"
                                (click)="goToEntreprise()"
                                translate>
                                sitemap-modal.doCompany
                            </a>
                        </li>
                        <li class="">
                            <a
                                href="javascript:void(0)"
                                (click)="goToProject()"
                                translate>
                                sitemap-modal.doProject
                            </a>
                        </li>
                        <li class="">
                            <a
                                href="{{goToEditProfile()}}"
                                translate>
                                sitemap-modal.myAccount
                            </a>
                        </li>

                        <li class="">
                            <a
                                href="{{goToEditPassword()}}"
                                translate>
                                sitemap-modal.doPassword
                            </a>
                        </li>
                        <li class="">
                            <a
                                href="{{goToEditPreferences()}}"
                                translate>
                                sitemap-modal.doPreferences
                            </a>
                        </li>
                        <li class="">
                            <a
                                href="javascript:void(0)"
                                (click)="logout()"
                                translate>
                                sitemap-modal.doSignOut
                            </a>
                        </li>
                        <li>
                            <a href="#" title="Page accessible seulement en mode non connecté" aria-disabled="true"
                               disabled="disabled" translate>sitemap-modal.doLoginPage</a>
                        </li>
                        <li>
                            <a href="#" title="Page accessible seulement en mode non connecté" aria-disabled="true"
                               disabled="disabled" translate>
                                sitemap-modal.doRegister
                            </a>
                        </li>
                        <li>
                            <a href="#" title="Page accessible seulement en mode non connecté" aria-disabled="true"
                               disabled="disabled" translate>sitemap-modal.doForgotPasswordLink</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button type="button" class="btn float-right" (click)="cancel()" [attr.aria-label]="'Close' | translate" translate>
                    Close
                </button>
            </div>
        </div>
    </div>
</app-modal>
