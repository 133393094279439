import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user';
import { EntityRoles } from '../../models/entity';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalInvite } from './invite-modal/invite-modal.component';
import { GroupService } from 'src/app/services/gdi-api/group.service';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-entity-manager',
    templateUrl: './entity-manager.component.html',
    styleUrls: ['./entity-manager.component.scss']
})
export class EntityManagerComponent implements OnInit {
    siret: string;
    companyName: string;
    waitingRightsHolders: User[];
    rightsHolders: User[];
    representatives: User[];
    currentUser: User;
    currentUserRole: string;

    faBuilding = faBuilding;

    constructor(
        protected route: ActivatedRoute,
        protected auth: AuthService,
        protected api: GroupService,
        protected modal: ModalService,
        protected translate: TranslateService
    ) {}

    ngOnInit() {
        // this.modal.loader(true);
        this.loadCompany();
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    loadCompany() {
        this.auth.getUser().then(user => {
            if (user) {
                this.currentUser = user;
                const siret = this.route.snapshot.paramMap.get('id');
                this.api
                    .getCompany(siret)
                    .then(response => {
                        if (!response['hasError']) {
                            const result = response;
                            const tmpRepresentatives: User[] = [];
                            result['groups'][EntityRoles.Mandataire].forEach(element => {
                                if (this.currentUser && element['id'] === this.currentUser.id) {
                                    const tmpUser = new User(element['id'], 'Vous');
                                    tmpRepresentatives.push(tmpUser);
                                } else {
                                    const tmpUser = new User(element['id'], element['firstname'], element['lastname']);
                                    tmpRepresentatives.push(tmpUser);
                                }
                            });

                            const tmpRightsHolders: User[] = [];
                            result['groups'][EntityRoles.AyantDroit].forEach(element => {
                                const tmpRightHolder = new User(element['id'], element['firstname'], element['lastname']);
                                tmpRightsHolders.push(tmpRightHolder);
                            });

                            const tmpWaittingRightsHolders: User[] = [];
                            result['groups'][EntityRoles.EnAttenteAyantDroit].forEach(element => {
                                const tmpWaittingRightHolder = new User(element['id'], element['firstname'], element['lastname']);
                                tmpWaittingRightsHolders.push(tmpWaittingRightHolder);
                            });
                            this.siret = result['siret'];
                            this.companyName = result['display-name'];
                            this.representatives = tmpRepresentatives;
                            this.rightsHolders = tmpRightsHolders;
                            this.waitingRightsHolders = tmpWaittingRightsHolders;
                            //this.modal.loader(false);
                        } else {
                            throw response;
                        }
                    })
                    .catch(raisedError => {
                        this.raiseError(raisedError);
                    });
            }
        });
    }

    validateUser($event) {
        const confirm = this.t('confirmAllowUser', $event.validatedUser);
        this.modal.confirm({
            text: confirm,
            onSuccess: () => {
                //this.modal.loader(true);
                this.api
                    .validateEnAttenteDeValidation(this.siret, $event.validatedUser.id)
                    .then(response => {
                        this.loadCompany();
                    })
                    .catch(raisedError => {
                        this.raiseError(raisedError);
                    });
            }
        });
    }

    removeUser($event) {
        const isRightHolder = this.rightsHolders.find(user => {
            return user.id === $event.user.id;
        });
        const confirm = isRightHolder
            ? this.t('confirmRemoveUserFromCompany', $event.user)
            : this.t('confirmRemoveWaitingUserFromCompany', $event.user);
        this.modal.confirm({
            text: confirm,
            onSuccess: () => {
                //this.modal.loader(true);
                this.api
                    .removeUserFromGroup(this.siret, $event.user.id)
                    .then(response => {
                        if (response['hasError']) {
                            throw response;
                        } else {
                            this.loadCompany();
                        }
                    })
                    .catch(raisedError => {
                        this.raiseError(raisedError);
                    });
            }
        });
    }

    inviteUsers($event) {
        this.modal.show(ModalInvite, {
            onSend: (recipients, message) => {
                //this.modal.loader(true);

                this.api
                    .inviteGroup(this.siret, recipients, message)
                    .then(result => {
                        this.modal.alert(this.t('invitationSent'));
                        //this.modal.loader(false);
                    })
                    .catch(error => {
                        this.raiseError(error);
                    });
            }
        });
    }

    raiseError(error) {
        //this.modal.loader(false);
        let msg = this.t('errorCommon');
        if (error) {
            console.error('Api error', error);
            if (error.messages) {
                msg = Object.keys(error.messages)
                    .map(key => error.messages[key])
                    .join('<br/>');
            }
        }
        this.modal.alert(msg);
    }
}
