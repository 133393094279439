import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ModalRgpd } from '../modal/rgpd-modal/rgpd-modal.component';
import { ModalCgu } from '../modal/cgu-modal/cgu-modal.component';
import { ModalAccessibilite } from '../modal/accessibilite-modal/accessibilite-modal.component';
import { ModalPlanDuSite } from '../modal/plandusite-modal/plandusite-modal.component';
import { environmentLoader } from '../../../environments/environmentLoader';
import { ModalCookies } from '../modal/cookies-modal/cookies-modal.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    contact: string;

    constructor(protected modalService: ModalService) {
        environmentLoader.then(env => {
            this.contact = env.settings.support;
        });
    }

    ngOnInit(): void {}

    openRgpdModal($event) {
        this.modalService.hide(ModalCgu);
        this.modalService.hide(ModalCookies);
        this.modalService.hide(ModalAccessibilite);
        this.modalService.hide(ModalPlanDuSite);
        this.modalService.show(ModalRgpd);
    }

    openCguModal($event) {
        this.modalService.hide(ModalRgpd);
        this.modalService.hide(ModalCookies);
        this.modalService.hide(ModalAccessibilite);
        this.modalService.hide(ModalPlanDuSite);
        this.modalService.show(ModalCgu);
    }

    openCookiesModal($event) {
        this.modalService.hide(ModalCgu);
        this.modalService.hide(ModalRgpd);
        this.modalService.hide(ModalAccessibilite);
        this.modalService.hide(ModalPlanDuSite);
        this.modalService.show(ModalCookies);
    }
    openAccessibiliteModal($event) {
        this.modalService.hide(ModalCgu);
        this.modalService.hide(ModalRgpd);
        this.modalService.hide(ModalCookies);
        this.modalService.hide(ModalPlanDuSite);
        this.modalService.show(ModalAccessibilite);
    }
    openPlanDuSiteModal($event) {
        this.modalService.hide(ModalCgu);
        this.modalService.hide(ModalRgpd);
        this.modalService.hide(ModalCookies);
        this.modalService.hide(ModalAccessibilite);
        this.modalService.show(ModalPlanDuSite);
    }
}
