import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Entity } from '../models/entity';

export enum SortingType {
    Asc = 'alphabetic',
    Desc = 'inverse'
}

@Pipe({
    name: 'sortEntity'
})
export class SortEntityPipe implements PipeTransform {
    constructor() {}
    transform(entities: Entity[], order = SortingType.Asc, on = 'displayName'): Entity[] {
        entities.sort((entity1, entity2) => {
            if (entity1[on] > entity2[on]) {
                return 1;
            }
            if (entity1[on] < entity2[on]) {
                return -1;
            }
            return 0;
        });

        return order === SortingType.Asc ? entities : entities.reverse();
    }
}
