import { ApiService } from './api.service';
import { Injector } from '@angular/core';

export abstract class AbstractService {
    protected endpoint: string;
    protected api: ApiService;

    constructor(injector: Injector) {
        this.api = injector.get(ApiService);
    }

    get(params: object = {}) {
        return this.api.get(this.endpoint);
    }

    post(params: object = {}) {
        return this.api.post(this.endpoint, params);
    }

    put(params: object = {}) {
        return this.api.put(this.endpoint, params);
    }
}
