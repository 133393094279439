<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="content">
        <div class="row occ-modal-header">
            <h5 class="col-11 occ-modal-title"></h5>
            <button
                type="button"
                class="col-1 cancel-btn link-custom-focus"
                (click)="cancel()"
                placement="left"
                ngbTooltip="{{ 'Close' | translate }}"
            >
                <span>
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </button>
        </div>

        <div [class]="showIframe ? 'occ-modal-body-long' : 'occ-modal-body'">
            <div class="row" *ngIf="!showIframe; else iframe">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <strong>{{ content }}</strong>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center pt-4" *ngIf="showCaptacha">
                        <ngx-recaptcha2
                            #captchaElem
                            name="captcha"
                            [siteKey]="siteKey"
                            [size]="'normal'"
                            (expire)="onReCaptchaExpiration(captchaElem)"
                            (success)="onReCaptcha(true)"
                            (reset)="showCaptachaAct()"
                            [(ngModel)]="captcha"
                            [ngModelOptions]="{ standalone: true }"
                        >
                        </ngx-recaptcha2>
                    </div>
                    <div class="row pt-4">
                        <div class="col">
                            <button class="btn " #focus translate (click)="completeValidation()" [class.disabled]="!isCaptchaValidated">
                                validation.completeDossier
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #iframe>
                <div class="row">
                    <div class="col">
                        <div class="col">
                            <p translate class="sm-md-text text-align-center title">upload-file.titleAssociation</p>
                        </div>

                        <div class="separator">
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col">
                                <p translate class="sm-text text-align-center sub-title">upload-file.sub-title</p>
                            </div>
                        </div>

                        <div *ngIf="showIframe && netheosUrl" class="iframe-netheos row">
                            <div class="col fill-available">
                                <iframe
                                    id="signbook"
                                    [src]="netheosUrl | sanitizer"
                                    scrolling="yes"
                                    frameBorder="no"
                                    width="100%"
                                    height="430px"
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</app-modal>
