import { Injectable, Output, EventEmitter } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Entity, EntityTypes, EntityRoles } from '../../models/entity';
import { Attribute } from '../../models/attribute';
import { User } from '../../models/user';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
    entities: Entity[] = [];
    entitiesLoaded: boolean;

    constructor(private keycloak: KeycloakService) {}

    hasEntities() {
        if (this.entitiesLoaded) {
            return this.entities.length > 0;
        }
        return this.getEntities().length > 0;
    }

    getUserEntities() {
        const keycloak = this.keycloak.getKeycloakInstance();
        let res = { groups: [] };
        try {
            res = JSON.parse(keycloak.tokenParsed['entities']);
        } catch (e) {}
        return res.groups;
    }

    refreshEntities() {
        this.entities = [];
        return this.getEntities();
    }

    getUser(): Promise<any> {
        const keycloak = this.keycloak.getKeycloakInstance();
        let user = null;
        if (keycloak.tokenParsed) {
            const data: any = keycloak.tokenParsed;
            user = new User();
            user.id = data.sub;
            user.username = data.preferred_username;
            user.email = data.email;
            user.firstname = data.given_name;
            user.lastname = data.family_name;
            if (data.birthday) {
                user.add(new Attribute('birthday', data.birthday));
            }
            if (data.default_entity) {
                user.add(new Attribute('default_entity', [data.default_entity]));
            }
            if (data.netheos_attributes_token) {
                user.add(new Attribute('netheos_attributes_token', [data.netheos_attributes_token]));
            }
            if (data.netheos_attributes_uuid) {
                user.add(new Attribute('netheos_attributes_uuid', [data.netheos_attributes_uuid]));
            }
        }
        return Promise.resolve(user);
    }

    refreshUser(): Promise<{ user: any; entities: Entity[] }> {
        // 2 * 24 * 60 * 60 = 2j => to force token update
        return this.keycloak.updateToken(2 * 24 * 60 * 60).then(result => {
            return this.getUser().then(user => {
                const entities = this.refreshEntities();
                return { user, entities };
            });
        });
    }

    getUserDefaultEntity(): Promise<string> {
        return this.getUser().then((user: User) => {
            return user.getDefaultEntity();
        });
    }

    getEntities() {
        const tmpEntities = this.getUserEntities();

        if (!this.entities.length && tmpEntities) {
            Object.keys(tmpEntities).forEach(name => {
                const entity = tmpEntities[name];
                const role = entity.role || EntityRoles.EnAttenteAyantDroit;
                const type = entity.attributes['type'] ? entity.attributes['type'][0] : EntityTypes.Company;

                const tmpEntity = new Entity(name, type, role);

                Object.keys(entity.attributes).forEach(key_ => {
                    const tmpAttribute = new Attribute(key_, entity.attributes[key_]);
                    tmpEntity.add(tmpAttribute);
                });

                this.entities.push(tmpEntity);
            });
            this.entitiesLoaded = true;
        }
        return this.entities;
    }

    getEntityByName(name: string): Entity {
        let result = null;
        this.entities.forEach(entity => {
            if (entity.name === name) {
                result = entity;
            }
        });
        return result;
    }

    getEntityBySiret(siret: string): Entity {
        let result = null;
        this.refreshEntities();
        this.entities.forEach(entity => {
            if (entity.name.replace(/\s/g, '') === siret) {
                result = entity;
            }
        });
        return result;
    }

    deleteEntity(entity: Entity) {
        if (this.entities.splice(this.entities.indexOf(entity)).length > 0) {
            // Entity deleted successfully
            this.hasEntities();
        } else {
            // An error occured while attempting to delete entity
        }
    }

    addTokenToHeader(headers) {
        return this.keycloak.addTokenToHeader(headers);
    }

    isLoggedIn() {
        return this.keycloak.isLoggedIn();
    }

    logout(redirect_uri) {
        this.keycloak.logout(redirect_uri);
    }

    getUserRoles() {
        return this.keycloak.getUserRoles();
    }

    getUserProfile() {
        return this.keycloak.loadUserProfile();
    }
}
