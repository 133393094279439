import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-company-simple-card',
    templateUrl: './company-simple-card.component.html',
    styleUrls: ['./company-simple-card.component.scss']
})
export class CompanySimpleCardComponent implements OnInit {
    @Input()
    siret: string;

    @Input()
    raisonSociale: string;

    constructor() {}

    ngOnInit() {}
}
