import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    /**
     * Truncate string and add '...' if string length is over displayChars
     * @param value to truncate
     * @param displayChars to keep and display
     */
    transform(value: string, displayChars = 50): string {
        return value.slice(0, displayChars) + (value.length > displayChars ? '...' : '');
    }
}
