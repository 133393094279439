import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-api-unvailable',
    templateUrl: './api-unvailable.component.html',
    styleUrls: ['./api-unvailable.component.scss']
})
export class ApiUnvailableComponent implements OnInit {
    @Input() subtitle: string;
    @Input() firstMessage: string;
    @Input() secondMessage: string;
    @Input() actionButtonText: string;
    @Output() action = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    doAction() {
        this.action.emit();
    }
}
