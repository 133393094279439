import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner-dots',
    templateUrl: './spinner-dots.component.html',
    styleUrls: ['./spinner-dots.component.css']
})
export class SpinnerDotsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
