<nav id="mainMenu" class="d-flex justify-content-between align-items-center">
    <ng-container *ngIf="isInternalPage; else backLink">
        <a (click)="goBack()" href="javascript:void(0)" id="backtoHomeLink" class="pointer link-custom-focus" [attr.aria-label]=" 'back' | translate "
        >
            <span translate><fa-icon icon="angle-double-left"></fa-icon> back</span></a>
    </ng-container>
    <ng-template #backLink>
        <ng-container *ngIf="backlink; else placeholder">
            <a (click)="goBack()" id="backtoAppLink" href="javascript:void(0)" class="pointer link-custom-focus" [attr.aria-label]=" 'backToApp' | translate ">
                <span translate><fa-icon icon="angle-double-left"></fa-icon> backToApp</span>
            </a>
        </ng-container>
    </ng-template>
    <ng-template #placeholder>&nbsp;</ng-template>
    <a (click)="logout()" href="javascript:void(0)" class="pointer d-flex align-items-center link-custom-focus"
       [attr.aria-label]=" 'logout' | translate " id="logoutLink">
        <span translate>logout <fa-icon [icon]="faSignOutAlt"></fa-icon></span>
    </a>
</nav>
