<div *ngIf="!isLoading">
    <!-- Partie formulaire -->
    <div *ngIf="!showProjectCard && !hasError">
        <!-- Image temporaire en attendant la vraie image -->
        <div class="image-div">
            <img alt="" class="page-image centered-element custom-image" src="assets/img/illustration_projet_v2.png" />
            <div class="link-div"><a (click)="showCreditModal()" class="credit-link" translate>credit</a></div>
        </div>
        <h1 class="md-text text-align-center which-company-to-manage" id="searchTitle" translate>
            whichProjectToCreate
        </h1>
        <app-form-project
            (submit)="searchProject($event)"
            [autofocus]="true"
            [errorMessage]="'siretNotFound'"
            [errorState]="comingFromBadSiret"
            [project]="project"
            [submitAction]="'create'"
        >
        </app-form-project>
    </div>

    <!-- Partie nom de projet non disponible -->
    <div *ngIf="hasError && !isProjectNameAvailable && !showProjectCard" class="d-flex flex-column align-items-center">
        <img alt="logo" class="centered-element page-image" src="assets/img/erreur.png" />
        <div class="text-align-center">
            <div style="margin-bottom: 35.6px">
                <h3 class="siret-not-found">{{ errorMessage }}</h3>
            </div>
            <div class="separator">
                <hr />
            </div>
        </div>
        <div class="check-your-entry text-align-center">
            <span class="sm-text" translate>changeYourEntry</span>
        </div>
        <button (click)="resetPage(project)" class="btn" translate type="button">
            modifyMyProjectName
        </button>
    </div>
</div>

<app-loading-screen *ngIf="isLoading" [isCompany]="false"></app-loading-screen>

<!-- Modale lorsque l'entreprise est exclue de l'application -->
<app-modal (close)="resetPage()" [isOpen]="isModalOpen">
    <p class="content" translate>
        excludedCompaniesMessage
    </p>
    <button (click)="resetPage()" class="btn" translate>validation.otherSiret</button>
</app-modal>

<!-- Modale lors du clic sur crédits sous l'image-->
<app-modal (close)="hideCreditModal()" [isOpen]="isCreditModalOpen">
    <p class="content" translate>
        Illustrations are derivatives of "We are humaaan after all", made by Pablo Stanley (used under
        <a href="https://creativecommons.org/licenses/by/4.0/">CC BY</a>) and from <a href="http://www.freepik.com/">Freepik</a>
    </p>
    <button (click)="hideCreditModal()" class="btn" translate>creditOkButton</button>
</app-modal>
