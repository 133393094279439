import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entity, EntityTypes } from '../../../models/entity';
import { User } from '../../../models/user';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faTrash, faRocket, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { environmentLoader } from 'src/environments/environmentLoader';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-entity-list',
    templateUrl: './entity-list.component.html',
    styleUrls: ['./entity-list.component.scss']
})
export class EntityListComponent {
    @Input()
    entities: Entity[] = [];
    @Input()
    currentUser: User;
    @Output()
    select = new EventEmitter<object>();
    @Output()
    remove = new EventEmitter<object>();
    @Output()
    checkAffiliation = new EventEmitter<object>();

    faTrash = faTrash;
    faRocket = faRocket;
    faCheckCircle = faCheckCircle;
    useApiEntreprise: boolean = false;

    constructor(
        protected modal: ModalService,
        private keycloak: KeycloakService,
        private localStorage: LocalStorageService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        environmentLoader.then(env => {
            this.useApiEntreprise = env.settings.api.enableApiEntreprise;
        });
    }

    isCompany(entity) {
        return entity.type === EntityTypes.Company;
    }

    isTransformedProject(entity) {
        return entity.type === EntityTypes.TransformedProject;
    }

    isProject(entity) {
        return entity.type === EntityTypes.Project;
    }

    selectEntity(entity: Entity) {
        entity.isSelected = !entity.isSelected;
        entity.isSelected ? this.select.emit({ entity }) : this.select.emit({});
    }

    removeEntity(event, entity): void {
        event.stopPropagation();
        this.remove.emit({ entity });
    }

    checkCompanyAffiliation($event): void {
        this.checkAffiliation.emit($event);
    }

    getDefaultLogoImage(entity: Entity): string {
        if (entity.type === EntityTypes.Project || entity.type === EntityTypes.TransformedProject) {
            return 'assets/img/projet.png';
        }
        return 'assets/img/Illustration_entreprise_resultat.png';
    }

    goToCompanySiretSearchPage(value): void {
        this.localStorage.setProjectId(value);
        this.localStorage.setTransformProject('true');
        this.router.navigate(['entreprise'], {
            queryParams: {
                service: this.route.snapshot.queryParams['service'],
                redirect_uri: this.route.snapshot.queryParams['redirect_uri']
            }
        });
    }

    isLienNonVerifieRole(entity: Entity): boolean {
        return entity.getRoleLabel() === "Lien non vérifé avec l'entreprise";
    }
}
