import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ModalCgu = 'cgu';

@Component({
    selector: 'app-cgu-modal',
    templateUrl: './cgu-modal.component.html',
    styleUrls: ['./cgu-modal.component.scss']
})
export class CguModalComponent implements OnInit {
    modalOpen = false;
    faTimes = faTimes;

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.modalService.register(ModalCgu, this);
    }

    // Mandatory para Modal Service
    show() {
        this.modalOpen = true;
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
    }

    onCancel() {}
    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }
}
