import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/models/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { AuthService } from '../../services/auth/auth.service';
import { AllowedRedirectUrisService } from '../../services/gdi-api/allowed-redirect-uris.service';
import { IsInternalPageService } from '../../services/is-internal-page.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { environmentLoader } from '../../../environments/environmentLoader';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
    faAngleDoubleLeft = faAngleDoubleLeft;
    faSignOutAlt = faSignOutAlt;

    isInternalPage: boolean;
    redirectUri: string;
    defaultEntity: boolean;
    user: User;
    isRedirected: boolean;
    backlink: boolean;

    protected keycloakUrl: string;
    protected keycloakRealm: string;

    subscriptions: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService,
        private localStorageService: LocalStorageService,
        private _location: Location,
        protected isInternalPageService: IsInternalPageService,
        private allowedRedirectUrisService: AllowedRedirectUrisService
    ) {
        this.subscriptions.push(this.isInternalPageService.currentValue.subscribe(val => (this.isInternalPage = val)));
    }

    ngOnInit() {
        this.refreshIsInternalPage();
        environmentLoader.then(env => {
            this.keycloakUrl = env.settings.auth.keycloak.url;
            this.keycloakRealm = env.settings.auth.keycloak.realm;
        });
        this.subscriptions.push(
            this.allowedRedirectUrisService.allowedRedirectUris$.subscribe((allowedRedirectUris: string[]) => {
                this.refreshBackLink();
            })
        );
        this.subscriptions.push(
            this.route.queryParams.subscribe((params: Params) => {
                this.backlink = true;
                this.redirectUri = this.localStorageService.getRedirectUri();
                this.refreshBackLink();

                this.authService.getUser().then(user => {
                    this.user = user;
                    if (user && this.redirectUri) {
                        const defaultEntityID = user.getDefaultEntity();
                        this.defaultEntity = !!this.authService.getEntities().find(entity => {
                            return entity.name === defaultEntityID;
                        });
                    }
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subecritpion: Subscription) => {
            subecritpion.unsubscribe();
        });
    }

    // Common
    logout() {
        this.authService.logout(this.redirectUri);
    }

    goBack() {
        if (this.isInternalPage) {
            this.router.navigate(['/'], {
                queryParamsHandling: 'preserve'
            });
        } else {
            if (this.redirectUri) {
                window.location.href = this.redirectUri;
            } else {
                this.router.navigate(['/'], {
                    queryParamsHandling: 'preserve'
                });
            }
        }
    }

    refreshIsInternalPage() {
        this.isInternalPage = this.isInternalPageService.isInternalPageSubject.getValue();
    }

    refreshBackLink() {
        this.backlink =
            this.redirectUri !== window.location.href &&
            this.redirectUri != null &&
            this.allowedRedirectUrisService.isValidRedirectURI(this.redirectUri);
    }
}
