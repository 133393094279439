<button
    *ngIf="isMandataire && isVerified"
    (click)="accessToManager($event)"
    placement="bottom"
    ngbTooltip="{{ 'alt.manageAccess' | translate }}"
    class="link-custom-focus entity-access-manager-btn"
    [attr.aria-label]="'alt.manageAccess' | translate"
>
    <span><fa-icon [icon]="faUserCog"></fa-icon></span>
</button>
