import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router, protected keycloakService: KeycloakService, private localStorageService: LocalStorageService) {
        super(router, keycloakService);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(resolve => {
            this.localStorageService.setService(route.queryParams['service']);
            const service = this.localStorageService.getService();

            const redirectUri = this.localStorageService.getRedirectUri();

            if (!this.authenticated) {
                let queryParams = '?';
                queryParams += service ? `service=${service}&` : '';
                queryParams += redirectUri ? `redirect_uri=${redirectUri}` : '';

                this.keycloakService.login({
                    redirectUri: `${window.location.origin}/${queryParams}` // Append state.url to point on current page
                });
                return resolve(false);
            }
            return resolve(true);
        });
    }
}
