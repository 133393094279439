<mwl-gauge
    [max]="100"
    [dialStartAngle]="-90"
    [dialEndAngle]="-90.001"
    [value]="percentageCompletion"
    [label]="customizedLabel"
    [animated]="true"
    [animationDuration]="1"
    placement="bottom"
    ngbTooltip="{{ tooltip | translate }}"
>
</mwl-gauge>
