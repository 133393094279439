import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Entity, EntityTypes } from '../../../models/entity';

@Component({
    selector: 'app-entity-add',
    templateUrl: './entity-add.component.html',
    styleUrls: ['./entity-add.component.scss']
})
export class EntityAddComponent implements OnInit {
    @Input() entities: Entity[] = [];
    @Input() defaultType: string;
    @Output() create = new EventEmitter<object>();

    showForm = false;
    entityType: string = EntityTypes.Company;
    newSiret: string;
    newProjectName: string;

    ngOnInit() {
        if (this.defaultType) {
            this.entityType = this.defaultType;
            this.showForm = true;
        }
    }

    toogleEntityType() {
        if (this.entityType === EntityTypes.Company) {
            this.entityType = EntityTypes.Project;
        } else {
            this.entityType = EntityTypes.Company;
        }
    }

    typeCompany() {
        return this.entityType === EntityTypes.Company;
    }

    typeProject() {
        return this.entityType === EntityTypes.Project;
    }

    // Entity creation part
    onSubmit(entityType, form) {
        const formReset = () => {
            form.reset();
        };
        if (entityType === 'COMPANY') {
            this.create.emit({
                formReset,
                entity: { type: EntityTypes.Company, name: this.newSiret }
            });
        }
        if (entityType === 'PROJECT') {
            this.create.emit({
                formReset,
                entity: { type: EntityTypes.Project, name: this.newProjectName }
            });
        }
    }

    // Allow only digits
    onlyDigits(event) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    formatSiret(value) {
        let newVal = value.replace(/\D/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length < 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length < 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2');
        } else if (newVal.length < 9) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1 $2 $3');
        } else {
            newVal = newVal.substring(0, 14);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,5})/, '$1 $2 $3 $4');
        }
        this.newSiret = newVal;
    }
}
