export const netheos = {
    signbook: 'contract/signbook/signbook.html?token=',
    authentificationSeule: 'Authentification_seule',
    authentificationAvecCerfa: 'Authentification_avec_Cerfa'
};

export const netheosStatusOfFiles = {
    initialized: 'INITIALIZED',
    pending: 'PENDING',
    waiting: 'WAITING',
    suspended: 'SUSPENDED',
    accepted: 'ACCEPTED',
    rejected: 'REJECTED'
};

export const userProfile = {
    mandataire: 'mandataire',
    ayantDroit: 'ayant-droit',
    enAttente: 'en-attente-ayant-droit'
};

export const netheosEventSignbook = {
    userEvent: 'userEvent',
    clientFileEvent: 'clientFileEvent',
    errorEvent: 'errorEvent',
    displayEvent: 'displayEvent',
    unchanged: 'unchanged'
};

export const netheosEventAction = {
    exit: 'EXIT'
};

export const endpoints = {
    netheos: 'gdi-entreprise/netheos',
    netheosCreateDossier: 'gdi-entreprise/netheos/createDossier',
    netheosVerifyIdentity: 'gdi-entreprise/netheos/verifyIdentity'
};

export const encryptor = {
    passphrase: 'FVE64YGYR0RJFBTVRE7897RVV'
};

export const joinGroupMessages_ActionNotAble = [
    'utilisateur.a.un.dossier.rejected',
    'utilisateur.a.un.dossier.waiting',
    'utilisateur.a.un.dossier.association.waiting'
];

export const joinGroupMessages_Rejected = ['utilisateur.a.un.dossier.rejected'];

export const joinGroupMessages_Pending = ['utilisateur.a.un.dossier'];

export const joinGroupMessages_Waiting = ['utilisateur.a.un.dossier.waiting', 'utilisateur.a.un.dossier.association.waiting'];

export const joinGroupMessages_IdentityFail = [
    'utilisateur.non.verifie',
    'utilisateur.a.un.dossier',
    'utilisateur.a.un.dossier.rejected',
    'utilisateur.a.un.dossier.waiting'
];

export const joinGroupMessages_LinkFail = [
    'utilisateur.non.verifie.association',
    'utilisateur.a.un.dossier.association',
    'utilisateur.a.un.dossier.association.rejected',
    'utilisateur.a.un.dossier.association.waiting',
    'en.attente.validation',
    'email.envoye.mandataire',
    'email.envoye.mandataires'
];

export const joinGroupMessages_Association = ['utilisateur.a.un.dossier.association'];

export const roleVerified = 'VERIFIED';

export const apiEntrepriseKO = 'Le service public API Entreprise ne répond pas.';
export const companyNotFound = "L''établissement {0} n''a pas été trouvé sur le service public API Entreprise.";
