<!-- IMPLEMENTATION JORDAN-->
<div id="clients-container" class="custom-col-9">
    <section>
        <div class="d-flex space-around align-items-baseline pb-2">
            <span class="text-informations" translate>selectClient</span>
        </div>
        <div class="d-flex justify-content-between align-items-baseline pl-3 offset-4">
            <button class="uppercase pointer client-buttons" placement="bottom"
                [ngClass]="{'button-clicked': clients[0].selected}" 
                (click)="selectClient(clients[0])">
                <div class="p-2">{{clients[0].name}}</div>
            </button>
        </div>
    </section>
    <section>
        <div class="d-flex space-around align-items-baseline pt-3">
            <span class="text-informations text-center" translate>spaceAccess</span>
        </div>
        <div class="d-flex justify-content-between ml-5 mr-5 pt-4 pb-2">
            <button *ngFor="let client of clients | slice:1" class="uppercase pointer client-buttons" placement="bottom"
            [ngClass]="{'button-clicked': client.selected}"   
                (click)="selectClient(client)">
                {{client.name}}
            </button>
        </div>
        <div class="d-flex space-around align-items-baseline pb-2">
            <span class="text-informations" translate>selectOrAddEntreprise</span>
        </div>
    </section>
</div>