import { Attribute } from 'src/app/models/attribute';

export class User {
    private _id: string;
    private _firstname: string;
    private _lastname: string;
    private _username: string;
    private _email: string;
    private _attributes: Attribute[];
    private _selected: boolean;

    constructor(
        id?: string,
        firstname?: string,
        lastname?: string,
        username?: string,
        email?: string,
        attributes?: Attribute[],
        selected = false
    ) {
        id ? (this._id = id) : (this._id = null);
        firstname ? (this._firstname = firstname) : (this._firstname = null);
        lastname ? (this._lastname = lastname) : (this._lastname = null);
        username ? (this._username = username) : (this._username = null);
        email ? (this._email = email) : (this._email = null);
        attributes ? (this._attributes = attributes) : (this._attributes = []);
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get firstname(): string {
        return this._firstname;
    }

    set firstname(value: string) {
        this._firstname = value;
    }

    get lastname(): string {
        return this._lastname;
    }

    set lastname(value: string) {
        this._lastname = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get attributes(): Attribute[] {
        return this._attributes;
    }

    set attributes(value: Attribute[]) {
        this._attributes = value;
    }

    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
    }

    add(value: Attribute) {
        this._attributes.push(value);
    }

    remove(value: Attribute) {
        this._attributes.splice(this._attributes.indexOf(value), 1);
    }

    getAttribute(attributeName: string): Attribute {
        let result = new Attribute();
        this._attributes.forEach(element => {
            if (element.name === attributeName) {
                result = element;
            }
        });
        return result;
    }

    getFullName(): string {
        return this.lastname + ' ' + this.firstname;
    }

    getDefaultEntity(): string {
        const defaultEntity = this.getAttribute('default_entity');
        return defaultEntity && defaultEntity.values[0];
    }
}
