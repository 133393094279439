import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-browser-detection',
    templateUrl: './browser-detection.component.html',
    styleUrls: ['./browser-detection.component.scss']
})
export class BrowserDetectionComponent implements OnInit {
    public show = true;
    public faTimes = faTimes;

    constructor() {}

    ngOnInit(): void {}

    isIEOrEdge(): boolean {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }

    hide(): void {
        this.show = false;
    }
}
