import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { environmentLoader } from '../../../../environments/environmentLoader';

export const ModalPlanDuSite = 'planDuSite';

@Component({
    selector: 'app-plandusite-modal',
    templateUrl: './plandusite-modal.component.html',
    styleUrls: ['./plandusite-modal.component.scss']
})
export class PlanDuSiteModalComponent implements OnInit {
    modalOpen = false;
    faTimes = faTimes;

    isInternalPage: boolean;
    redirectUri: string;
    defaultEntity: boolean;
    isRedirected: boolean;

    protected keycloakUrl: string;
    protected keycloakRealm: string;

    constructor(
        private modalService: ModalService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private localStorage: LocalStorageService,
        private localStorageService: LocalStorageService
    ) {
        environmentLoader.then(env => {
            this.keycloakUrl = env.settings.auth.keycloak.url;
            this.keycloakRealm = env.settings.auth.keycloak.realm;
        });
    }

    ngOnInit() {
        this.modalService.register(ModalPlanDuSite, this);

        this.route.queryParams.subscribe((params: Params) => {
            this.isRedirected = params['redirect_uri'] != null;
            this.redirectUri = this.localStorageService.getRedirectUri();
        });
    }

    // Mandatory para Modal Service
    show() {
        this.modalOpen = true;
    }

    // Mandatory para Modal Service
    hide() {
        this.modalOpen = false;
    }

    onCancel() {}

    cancel() {
        this.onCancel();
        this.modalOpen = false;
    }

    showTarteaucitronPopIn() {
        return window.location + '#tarteaucitron';
    }

    goToEntreprise() {
        this.localStorage.setProjectId(null);
        this.router.navigate(['entreprise'], {
            queryParams: {
                service: this.route.snapshot.queryParams['service']
            }
        });
        this.cancel();
    }
    goToProject() {
        this.router.navigate(['projet'], {
            queryParams: {
                service: this.route.snapshot.queryParams['service']
            }
        });
        this.cancel();
    }

    goToEditProfile() {
        return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account?referrer=${window.location}`;
    }

    goToEditPassword() {
        return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account/password?referrer=${window.location}`;
    }

    goToEditPreferences() {
        return `${this.keycloakUrl}/realms/${this.keycloakRealm}/account?contactPreferences=true&referrer=${window.location}`;
    }

    logout() {
        this.authService.logout(this.redirectUri);
    }
}
