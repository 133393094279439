import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'profil-completion-gauge',
    templateUrl: './profil-completion-gauge.component.html',
    styleUrls: ['./profil-completion-gauge.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfilCompletionGaugeComponent implements OnInit {
    @Input() percentageCompletion: number;
    @Input() tooltip: string;

    constructor() {}

    ngOnInit(): void {}

    customizedLabel(value: number) {
        return `${Math.round(value)}%`;
    }
}
