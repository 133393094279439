import { Injectable, Injector } from '@angular/core';
import { AbstractService } from './abstract.service';

@Injectable()
export class CompanyService extends AbstractService {
    constructor(injector: Injector) {
        super(injector);
        this.endpoint = 'gdi-entreprise/keycloak';
    }

    /**
     * This method retrieves company data from the government API thanks to a SIRET number
     *
     * @param {string} siret
     * @memberof CompanyService
     */
    getOneCompanyBySiret(siret: string) {
        return this.api.get(`${this.endpoint}/etablissements/${siret}`);
    }
}
