import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { AuthService } from './auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetheosService } from './gdi-api/netheos.service';

@Injectable()
export class ProfilCompletionService {
    private _user$$ = new BehaviorSubject<User>(null);
    private _user$: Observable<User>;
    private _forceVerify$$ = new BehaviorSubject<boolean>(false);
    private _forceVerify$: Observable<boolean>;
    private _forceVRefreshReCaptcha$$ = new BehaviorSubject<boolean>(false);
    private _forceVRefreshReCaptcha$: Observable<boolean>;
    private _netheosIdentityState$$ = new BehaviorSubject<string[]>([]);
    private _netheosIdentityState$: Observable<string[]>;
    private _netheosIdentityState: string[] = [];
    private _isVerfied$$ = new BehaviorSubject<boolean>(false);
    private _isVerfied$: Observable<boolean>;

    constructor(private auth: AuthService, private nehteosService: NetheosService) {
        this._user$ = this._user$$.asObservable();
        this.refreshUser();
        this._forceVerify$ = this._forceVerify$$.asObservable();
        this._netheosIdentityState$ = this._netheosIdentityState$$.asObservable();
        this.refreshIdentityState();
        this._forceVRefreshReCaptcha$ = this._forceVRefreshReCaptcha$$.asObservable();
        this._isVerfied$ = this._isVerfied$$.asObservable();
    }

    get user$(): Observable<User> {
        return this._user$;
    }

    get user(): User {
        return this._user$$.getValue();
    }

    set user(user: User) {
        this._user$$.next(user);
    }

    refreshUser() {
        this.auth.refreshUser().then(response => {
            this.auth.getUser().then((currentUser: User) => (this.user = currentUser));
        });
    }

    get forceVerify$(): Observable<boolean> {
        return this._forceVerify$;
    }

    get forceVerify(): boolean {
        return this._forceVerify$$.getValue();
    }

    set forceVerify(status: boolean) {
        this._forceVerify$$.next(status);
    }

    get forceVRefreshReCaptcha$() {
        return this._forceVRefreshReCaptcha$;
    }

    set forceVRefreshReCaptcha(status: boolean) {
        this._forceVRefreshReCaptcha$$.next(status);
    }

    refreshReCaptcha() {
        this._forceVRefreshReCaptcha$$.next(true);
    }

    get netheosIdentityState$(): Observable<string[]> {
        return this._netheosIdentityState$;
    }

    get netheosIdentityState(): string[] {
        return this._netheosIdentityState$$.getValue();
    }

    set netheosIdentityState(messages: string[]) {
        this._netheosIdentityState$$.next(messages);
    }

    refreshIdentityState() {
        if (this.nehteosService.isEnable()) {
            this.nehteosService
                .verifyIdentity()
                .then(response => this._netheosIdentityState$$.next(Object.keys(response.messages).filter(key => key.indexOf('_$') !== 0)));
        }
    }

    get isVerified$(): Observable<boolean> {
        return this._isVerfied$;
    }

    get isVerified(): boolean {
        return this._isVerfied$$.getValue();
    }

    set isVerified(isVerified: boolean) {
        this._isVerfied$$.next(isVerified);
    }

    refreshIsVerified() {
        this.isVerified = this.auth.getUserRoles().includes('VERIFIED');
    }
}
