import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Entity } from '../../models/entity';
import { GroupService } from '../../services/gdi-api/group.service';

@Component({
    selector: 'app-check-company-affiliation',
    templateUrl: './check-company-affiliation.component.html',
    styleUrls: ['./check-company-affiliation.component.scss']
})
export class CheckCompanyAffiliationComponent implements OnInit {
    @Input()
    entity: Entity;

    @Input()
    bText: boolean = false;

    @Output()
    checkAffiliation = new EventEmitter<object>();

    faLink = faLink;

    constructor(private api: GroupService) {}

    ngOnInit(): void {}

    checkCompanyAffiliation(event, entity) {
        event.stopPropagation();
        this.checkAffiliation.emit({ entity });
    }
}
