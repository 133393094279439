<div *ngIf="isIEOrEdge() && show" class="row">
    <div class="col">
        <div class="row ml-4 mr-4 mt-4 pl-2 pr-2 browser-compatibility">
            <div class="col p-1" translate>
                browserNotSupported
            </div>
            <div class="col-auto p-1">
                <fa-icon [icon]="faTimes" (click)="hide()" class="text-right btn-close"></fa-icon>
            </div>
        </div>
    </div>
</div>
