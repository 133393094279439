import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal/modal.service';

@Component({
    selector: 'app-pending-dossier',
    templateUrl: './pending-dossier.component.html',
    styleUrls: ['./pending-dossier.component.scss']
})
export class PendingDossierComponent implements OnInit {
    constructor(private router: Router, private modal: ModalService) {}

    ngOnInit() {}

    goToHome() {
        this.modal.hide('netheos');
    }
}
