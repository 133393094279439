import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity, EntityTypes } from '../../../models/entity';
import { User } from '../../../models/user';
import { ModalService } from 'src/app/services/modal/modal.service';
import { faTrash, faRocket, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { environmentLoader } from 'src/environments/environmentLoader';
import { Client } from 'src/app/models/client';

@Component({
    selector: 'app-client-button-list',
    templateUrl: './client-button-list.component.html',
    styleUrls: ['./client-button-list.component.scss']
})
export class ClientButtonListComponent {
    @Input()
    entities: Entity[] = [];
    @Input()
    clients: any[] = [];
    @Input()
    currentUser: User = new User();
    @Output()
    selectEvent = new EventEmitter<Client>();
    @Output()
    remove = new EventEmitter<object>();
    @Output()
    checkAffiliation = new EventEmitter<object>();

    faTrash = faTrash;
    faRocket = faRocket;
    faCheckCircle = faCheckCircle;
    isSelected = false;
    useApiEntreprise: boolean = false;

    constructor(protected modal: ModalService) {
        environmentLoader.then(env => {
            this.useApiEntreprise = env.settings.api.enableApiEntreprise;
        });
    }

    isCompany(entity) {
        return entity.type === EntityTypes.Company;
    }

    isTransformedProject(entity) {
        return entity.type === EntityTypes.TransformedProject;
    }

    isProject(entity) {
        return entity.type === EntityTypes.Project;
    }

    selectClient(client: Client) {
        client.selected = !client.selected;
        this.selectEvent.emit(client);
        setTimeout(() => (client.selected = false), 500);
    }
}
