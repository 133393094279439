import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/services/gdi-api/company.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { IsInternalPageService } from '../../services/is-internal-page.service';
import { apiEntrepriseKO, companyNotFound } from 'src/app/constants';
import { GroupService } from '../../services/gdi-api/group.service';
import { ModalService } from '../../services/modal/modal.service';
import { Subscription } from 'rxjs';
import { environmentLoader } from 'src/environments/environmentLoader';

@Component({
    selector: 'app-entreprise',
    templateUrl: './entreprise.component.html',
    styleUrls: ['./entreprise.component.scss']
})
export class EntrepriseComponent implements OnInit, OnDestroy {
    siret = '';
    projectID: any;
    accessProject: 'false';
    company: any;
    hasError = false;
    showCompanyCard = false;
    isLoading = false;
    isCompanyApiUnavailable = false;
    comingFromBadSiret = false;
    errorMessage = '';
    excludedNafs: Array<any>;
    excludedJuridicalForms: Array<any>;
    redirectUri: string;
    isModalOpen = false;
    isCreditModalOpen = false;
    useNetheos: boolean = false;
    typePersonne: string;
    raisonSocialeCompany: string;

    subscritpions: Subscription[] = [];

    constructor(
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private localStorageService: LocalStorageService,
        private isInternalPageService: IsInternalPageService,
        private groupService: GroupService,
        private modalService: ModalService
    ) {
        environmentLoader.then(env => {
            this.useNetheos = env.settings.netheos.enable;
        });
    }

    ngOnInit() {
        this.subscritpions.push(
            this.route.queryParams.subscribe(params => {
                this.redirectUri = params['redirect_uri'];
            })
        );
        if (this.localStorageService.getProjectName()) {
            this.localStorageService.deleteProject();
        }
        this.isInternalPageService.changeValue(true);
    }

    ngOnDestroy() {
        this.subscritpions.forEach((subscritpion: Subscription) => {
            subscritpion.unsubscribe();
        });
    }

    t(key: string, params?: object) {
        return this.translate.instant(key, params);
    }

    hideModal() {
        this.isModalOpen = false;
    }

    hideCreditModal() {
        this.isCreditModalOpen = false;
    }

    showCreditModal() {
        this.isCreditModalOpen = true;
    }

    searchCompany(params: any) {
        this.isLoading = true;
        const unspacedSiret = params.siret.replace(/\s+/g, '');
        this.projectID = params.project;
        this.showCompanyCard = false;
        this.comingFromBadSiret = false;
        this.companyService
            .getOneCompanyBySiret(unspacedSiret)
            .then((response: any) => {
                this.hasError = false;

                // Saves in localStorage
                this.localStorageService.setCompany(response.data);
                this.localStorageService.setSiret(params.siret);
                if (params.project !== '') {
                    this.localStorageService.setProjectId(params.project);
                }

                this.company = response.data;
                this.typePersonne = this.company.type;
                if (this.typePersonne === 'personne_morale') {
                    this.raisonSocialeCompany = this.company.personne_morale_attributs.raison_sociale;
                }
                if (this.typePersonne === 'personne_physique') {
                    this.raisonSocialeCompany = this.constructionRaisonSocialePersonnePhysique(
                        this.company.personne_physique_attributs.pseudonyme,
                        this.company.personne_physique_attributs.prenom_1,
                        this.company.personne_physique_attributs.prenom_2,
                        this.company.personne_physique_attributs.prenom_3,
                        this.company.personne_physique_attributs.prenom_4,
                        this.company.personne_physique_attributs.nom_usage,
                        this.company.personne_physique_attributs.nom_naissance
                    );
                }
                this.siret = params.siret;
                this.showCompanyCard = true;
                this.isLoading = false;
            })
            .catch((error: HttpErrorResponse) => {
                this.siret = params.siret;
                this.hasError = true;
                this.isLoading = false;
                this.isCompanyApiUnavailable = false;

                if (error.error) {
                    switch (error.error.message) {
                        case apiEntrepriseKO:
                            this.isCompanyApiUnavailable = true;
                            this.errorMessage = this.translate.instant('apiCompanyUnavailable').toString();
                            break;
                        case companyNotFound:
                            this.errorMessage = this.translate
                                .instant('companyNotFound')
                                .toString()
                                .replace('{siret}', params.siret);
                            break;
                    }
                }
            });
    }

    /**
     * Resets the workflow of this page with an optional SIRET to begin with
     * @param {string} siret siret
     */
    resetPage(siret = '') {
        this.siret = siret;
        this.hasError = false;
        this.isModalOpen = false;
        this.isCompanyApiUnavailable = false;
        this.showCompanyCard = false;
        this.localStorageService.setCompany('undefined');
        if (siret) {
            this.comingFromBadSiret = true;
        }
    }

    connectToCompany() {
        this.groupService
            .createGroup(this.siret, this.projectID !== null ? { 'keycloak-group-id': this.projectID } : null)
            .catch(errorResponse => {
                this.hasError = true;
                this.modalService.alertAction({
                    text: errorResponse.error.message,
                    buttonText: this.t('waiting-page.come-back-home'),
                    onSuccess: () => {
                        this.router.navigate(['/'], {
                            queryParamsHandling: 'preserve'
                        });
                    }
                });
            })
            .then(response => {
                if (response && response.messages) {
                    if (response.messages['utilisateur.pas.permis']) {
                        this.modalService.alertAction({
                            text: response.messages['utilisateur.pas.permis'],
                            buttonText: this.t('waiting-page.come-back-home'),
                            onSuccess: () => {
                                this.router.navigate(['/'], {
                                    queryParamsHandling: 'preserve'
                                });
                            }
                        });
                    } else if (!Object.keys(response.messages).length) {
                        this.router.navigate(['/'], {
                            queryParamsHandling: 'preserve'
                        });
                    }
                }
            });
    }

    continueWorkflow() {
        // Saves in localStorage
        this.localStorageService.setSiret(this.siret);
        if (this.projectID !== '') {
            this.localStorageService.setProjectId(this.projectID);
        }
        this.router.navigate(['/validation'], {
            queryParamsHandling: 'preserve'
        });
    }

    goBackToClientApp() {
        window.location.href = this.localStorageService.getRedirectUri();
    }

    /**
     * En v3 de l'API Entreprise, l'appel Entreprise voit apparaitre la distinction entre personne physique et personne moral
     * Cette fonction a pour but de construire la raison sociale des personnes physiques
     *
     * @param pseudonyme pseudonyme de la personne physique
     * @param prenom1 premier prenom de la personne physique
     * @param prenom2 deuxième prenom de la personne physique
     * @param prenom3 troisième prenom de la personne physique
     * @param prenom4 quatrième prenom de la personne physique
     * @param nomUsage nom d'usage de la personne physique
     * @param nomNaissance nom de naissance de la personne physique
     */
    constructionRaisonSocialePersonnePhysique(pseudonyme, prenom1, prenom2, prenom3, prenom4, nomUsage, nomNaissance) {
        let result = nomNaissance + '*';
        if (nomUsage !== null) {
            result = result + nomUsage;
        }
        result = result + '/';
        if (prenom1 !== null) {
            result = result + prenom1 + ' ';
        }
        if (prenom2 !== null) {
            result = result + prenom2 + ' ';
        }
        if (prenom3 !== null) {
            result = result + prenom3 + ' ';
        }
        if (prenom4 !== null) {
            result = result + prenom4 + ' ';
        }
        if (pseudonyme !== null) {
            result = result + pseudonyme + ' ';
        }
        result = result + '/';
        return result;
    }
}
