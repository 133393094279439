<app-modal [isOpen]="modalOpen" (close)="hide()">
    <div class="row pt-3 pl-2 pr-2">
        <div class="col">
            <p class="content">
                <strong [innerHTML]="text"></strong>
            </p>
        </div>
    </div>

    <div class="row pt-3">
        <div class="col">
            <button (click)="ok()" class="btn" [attr.aria-label]="'confirmYes' | translate" #confirm translate>
                confirmYes
            </button>
        </div>
        <div class="col">
            <button (click)="cancel()" class="btn occ-btn-secondary" [attr.aria-label]="'confirmNo' | translate" translate>
                confirmNo
            </button>
        </div>
    </div>
</app-modal>
